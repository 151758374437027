import image8 from "../../assests/images/work/youth/youthicon.png";
import {
  FaArtstation,
  FaBehance,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import mahindrasliderimage from "../../assests/carimages/mahindraslider_image.jpg";
import mahindrathumbnailimage from "../../assests/carimages/mahindraslider_image.jpg";
import youtholympic from "../../assests/carimages/youth_olympicsliderimage.jpg";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import disneyslide4 from "../../assests/carimages/Disney Pijama Ride VR_Game (1).webp";
import disneyslide5 from "../../assests/carimages/Disney Pijama Ride VR_Game (4).webp";
import olympic1 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (1).webp";
import olympic2 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (2).webp";
import olympic3 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (4).webp";
import olympic from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (5).webp";
import disneyslide3 from "../../assests/carimages/disney_image1.webp";
import screencraft from "../../assests/carimages/screencraft.png";
import nexon from "../../assests/carimages/tatanexon.webp";
import unique from "../../assests/data/unique";
import featureimage4 from "../../assests/images/Features/adaptive.webp";
import featureimage3 from "../../assests/images/Features/business.webp";
import featureimage1 from "../../assests/images/Features/cloud.webp";
import featureimage5 from "../../assests/images/Features/content.webp";
import featureimage2 from "../../assests/images/Features/realit.webp";
import chart from "../../assests/images/blogchart.webp";
import blog1 from "../../assests/images/blogsimages/blog1.png";
import blog2 from "../../assests/images/blogsimages/blog2.png";
import disneyslide2 from "../../assests/images/work/disneyslider/disneyslide2.png";
import mahindra1 from "../../assests/images/work/mahindra/back.jpg";
import mahindra from "../../assests/images/work/mahindra/light.jpg";
import mahindra2 from "../../assests/images/work/mahindra/opengate.jpg";
import mahindra3 from "../../assests/images/work/mahindra/wheel.jpg";
import youth from "../../assests/images/work/youth/youthicon.png";
import blogbanner from "../images/blogbanner.jpg";

let data = {
  faq: [
    {
      title: "What is Reality Scale? ",
      message:
        "RealityScale is a virtual twin provider, establishing a new-wave of technological solutions and innovative marketing by integrating artificial intelligence and cloud-centric approaches. Helping businesses transform their operations, asset demonstration and customer experience. ",
    },
    {
      title: "How can Reality Scale benefit my business? ",
      message:
        "Our solutions hinge on flexibility and personalization, enabling businesses to optimize and elevate product marketing and demonstration to unprecedented levels. Leveraging our cloud-based digital twin technology and toolkits, you can provide customers with detailed, interactive, hyper-realistic product experiences—from the comfort of their homes and on any device. Reaching a Global-Audience with cost-efficiency.",
    },
    {
      title: "What industries does Reality Scale serve? ",
      message:
        "Our solutions hinge on flexibility and personalization, enabling businesses to optimize and elevate product marketing and demonstration to unprecedented levels. Leveraging our cloud-based digital twin technology and toolkits, you can provide customers with detailed, interactive, hyper-realistic product experiences—from the comfort of their homes and on any device. Reaching a Global-Audience with cost-efficiency.",
    },
    {
      title: "What sets Reality Scale apart?  ",
      message:
        "We don't just create 3D virtual models or parallels; we build intelligent, interactive experiences and highly accessible assets that continually evolve with your chosen business parameters and streamline operations across the domains of e-commerce, supplemented by powerful AI-driven analytics and actionable insights. We have meticulously curated a tri-model technology, integrating Digital Twins with cloud-centered and machine learning approaches, setting us apart.",
    },
    {
      title: "Can Reality Scale develop custom applications for my business? ",
      message:
        "Starting with us is simple! Contact us today and schedule a demo. Where you can explore how our technology works through a demonstration. Further discuss how it can suitably be applied to your specific business needs. From there, you can receive a customized plan for your business to bring your assets into the smartness of the digital realm.",
    },
  ],
  gameData: [
    {
      image: "https://reality-scale-buck.s3.amazonaws.com/AI-Integration.jpg",
      heading: "AI/ML Hub",
      subHeading:
        "Powering intelligent solutions with advanced ML and AI technologies for diverse business needs.",
      url: "/transform/ai-ml-hub",
    },
    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/Automotive-Solutions.webp",
      heading: "Automotive Solutions",
      subHeading:
        "ZenithVision is Reality Scale's flagship cloud platform, dealerships, and customers interact with vehicles digitally.",
      url: "/transform/automotive",
    },

    {
      image: "https://reality-scale-buck.s3.amazonaws.com/Web3-Frontier.webp",
      heading: "Web3 Frontier ",
      subHeading:
        "Indulge into the future of digital innovation with our Metaverse, BlockChain and NFT resources.",
      url: "/transform/web3-frontier",
    },

    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/Real-Estate-Solutions.webp",
      heading: "Real Estate Solutions ",
      subHeading:
        "Revolutionize property tours with immersive 3D visualizations and Ai-driven marketing solutions.",
      url: "/transform/realestate-solution",
    },
    {
      image: "https://reality-scale-buck.s3.amazonaws.com/XR-Immersion.webp",
      heading: "XR Immersion",
      subHeading:
        "Explore the newer dimensions of visual experience with our interactive, augmented and virtual reality solutions.",
      url: "/transform/xr-immersion",
    },
    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/reality-scale-framework.webp",
      heading: "Reality Scale Framework ",
      subHeading:
        "Advanced cloud toolkit for scalable, high-fidelity products. Accelerate  with powerful features and seamless integration.",
      url: "/transform/realityscale-framework",
    },
  ],
  blogPosts: [
    {
      category: "TECHNOLOGY",
      date: "May 24, 2024",
      title: "How to Become a Graphic Designer in 10 Simple Steps",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius sequi commodi dignissimos optio, beatae, eos necessitatibus nisi. Nam cupiditate consectetur nostrum qui! Repellat natus nulla, nisi aliquid, asperiores impedit tempora sequi est reprehenderit cumque explicabo, dicta. Rem nihil ullam totam ea voluptas quibusdam repudiandae id ut at iure! Totam, a!",
    },
    {
      category: "TECHNOLOGY",
      date: "May 24, 2024",
      title: "How to Become a Graphic Designer in 10 Simple Steps",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius sequi commodi dignissimos optio, beatae, eos necessitatibus nisi. Nam cupiditate consectetur nostrum qui! Repellat natus nulla, nisi aliquid, asperiores impedit tempora sequi est reprehenderit cumque explicabo, dicta. Rem nihil ullam totam ea voluptas quibusdam repudiandae id ut at iure! Totam, a!",
    },
  ],
  testimonial: [
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
  ],

  projects: [
    {
      name: "AI/ML Hub",
      url: "/transform/ai-ml-hub",
    },
    {
      name: "Automotive Solutions",
      url: "/transform/automotive",
    },
    {
      name: "Web3 Frontier",
      url: "/transform/web3-frontier",
    },
    {
      name: "Real State Soltuions",
      url: "/transform/realestate-solution",
    },
    {
      name: "Xr Immersion",
      url: "/transform/xr-immersion",
    },
    {
      name: "Reality Scale Framework",
      url: "/transform/realityscale-framework",
    },
  ],

  usefulLinks: [
    { name: "Privacy Policy", url: "/" },
    { name: "Terms and conditions", url: "/" },
    "Cookie Policy",
    "Careers",
  ],
  product: [
    {
      pageurl: "disney-pyjama-ride",
      projectHeading: "Disney",
      projectsecongheading: "Pyjama Ride",
      name: "Disney Pyjama Ride",
      type: "VR/AR",
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide3,
          },
          {
            img: disneyslide4,
          },
          {
            img: disneyslide5,
          },
        ],
        paraHeading: "Disney Pyjama Ride",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      // voiceoverheading: "Voice over scripts",
      // scriptheading: "Script 1",
      // scriptlist: [
      //   {
      //     list: "Step into the world of Pyjama Ride, our thrilling VR project that turns virtual sledding into an unforgettable adventure for kids!",
      //   },
      //   {
      //     list: "Our team tackled the challenge of creating an intuitive control system that's easy for children to use, yet delivers an exhilarating experience. We developed a dynamic track system, allowing precise control over speed and shape to keep the excitement high.",
      //   },
      //   {
      //     list: "One of our proudest achievements is the vast, seamless world we built. Picture a multi-level mountain landscape filled with surprising events like collapsing rocks and flowing lava. It's an endless virtual roller coaster!",
      //   },
      //   {
      //     list: "But here's the real magic: our real-time recording system captures every thrilling moment, bringing the virtual experience into the real world.",
      //   },
      //   {
      //     list: "Pyjama Ride isn't just a game – it's a journey into the possibilities of VR. At Reality Scale, we're not just creating games; we're crafting dreams and making them virtually real.",
      //   },
      // ],
      // secondscriptheading: "Script 2",
      // secondscriptlist: [
      //   {
      //     list: "Developing Pyjama Ride was quite the roller coaster – pun intended. We set out to create a VR sledding game for kids, and boy, did we hit some bumps along the way.",
      //   },
      //   {
      //     list: "Our biggest challenge? Nailing those controls. We needed them simple enough for kids but still fun. Cue countless hours tweaking physics and chasing bugs – like when the sledge decided it wanted to be a rocket ship instead.",
      //   },
      //   {
      //     list: "Building a massive, seamless VR world nearly melted our machines. We got pretty creative with optimization just to keep the frame rate above slideshow levels.",
      //   },
      //   {
      //     list: "And don't get me started on the real-time event system. Syncing falling rocks and lava flows across the network? Let's just say it involved a lot of late-night debugging and energy drinks.",
      //   },
      //   {
      //     list: "In the end, we made something that kids enjoyed, even if it wasn't perfect. The real win was everything we learned about VR dev for young audiences. That knowledge? It's gold for our future projects.",
      //   },
      // ],
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      pageurl: "youth-olympic-project",
      projectHeading: "Youth",
      projectsecongheading: "Olympics Project",
      name: "Youth Olympics Project",
      type: "VR/AR",
      img: olympic,
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      parentname: "deepanshu",
      productDetails: {
        bannerImage: olympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "Youth Olympics Project",
        heading1: "BMX Olympic Showdown: AR Racing Reimagined",
        heading2: "Project Overview",
        para1:
          "BMX Olympic Showdown, an exhilarating multiplayer Augmented Reality racing game developed for the Winter Youth Olympics show floor. This project exemplifies our ability to create quick and fun experiences that engage and excite users in high-stakes environments using already existing technologies and at rapid speed.",
        para2:
          "The game was made to transform any floor into a virtual racing arena, allowing multiple players to compete in thrilling BMX races using iPad devices. The game combines the excitement of BMX racing with the immersive capabilities of XR and gives users an easy and competitive experience.",
        impact: "Impact",
        impactberief:
          "Olympic AR BMX demonstrates our capability to create engaging, accessible AR content for large-scale events rapidly. The game was enjoyed by thousands of participants during the Youth Olympics, bringing the excitement of BMX racing to life through the innovative use of existing XR technologies. This project showcases our expertise in swift development of impactful AR solutions that perform flawlessly in demanding, high-profile environments.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "Seamless multiplayer connectivity for real-time racing",
          },
          {
            list: "Intuitive controls optimised for iPad devices",
          },
          {
            list: "AR-powered course overlay on any physical floor",
          },
          {
            list: "High-quality animations and visual effects",
          },
          {
            list: "Quick-to-learn, challenging-to-master gameplay",
          },
        ],

        technicallist: [
          {
            head: "Efficient Multiplayer System:",
            list: "We utilised web sockets for seamless device connectivity, enabling multiple race sessions to run simultaneously on the show floor.",
          },
          {
            head: "Simple AR Synchronization:",
            list: "Our team implemented a straightforward AR scanning system to sync the game environment across all connected devices quickly.",
          },
          {
            head: "Low-Latency Action Communication:",
            list: " We created a streamlined system for transmitting and synchronising player actions across devices in real time.",
          },
          {
            head: "Flexible Session Management:",
            list: "Our solution allowed for easy setup of multiplayer sessions using iPads, facilitating quick turnover between participant groups",
          },
          {
            head: "Optimized AR Performance:",
            list: "The experience was fine-tuned to deliver smooth, engaging visuals and animations on iPad devices within a short development timeframe.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      // voiceoverheading: "Voice over scripts",
      // scriptheading: "Script 1",
      // scriptlist: [
      //   {
      //     list: "Imagine transforming any floor into an Olympic BMX racetrack with just a few iPads. That was our challenge for the Winter Youth Olympics, with only 60 days to deliver.",
      //   },
      //   {
      //     list: "Our mission: create a multiplayer augmented reality BMX racing game that is quick to learn, fun to play, and robust enough for thousands of users.",
      //   },
      //   {
      //     list: "We faced hurdles like syncing AR environments across devices and ensuring smooth multiplayer gameplay.",
      //   },
      //   {
      //     list: "Our approach was to leverage AR detection as both a positioning system and a player readiness indicator. We then utilised Web Sockets to create a local network of interconnected devices with the existing WiFi infrastructure. This combination of technologies resulted in a seamless multiplayer platform with precise spatial awareness and low-latency communication. ",
      //   },
      //   {
      //     list: "The result? BMX Olympic Showdown - an exhilarating AR racing experience that captivated thousands of participants. Players could jump into races within seconds, competing on virtual tracks overlaid on the physical floor.",
      //   },
      //   {
      //     list: "This is Reality Scale: where time constraints fuel innovation, not limit it.",
      //   },
      // ],
      // secondscriptheading: "Script 2",
      // secondscriptlist: [
      //   {
      //     list: "60 days to revolutionise Olympic entertainment with AR. Our challenge: transform any floor into a BMX racetrack using only iPads for the Winter Youth Olympics.",
      //   },
      //   {
      //     list: "We faced complex issues of spatial synchronisation and low-latency multiplayer functionality in a dynamic event environment. Our solution integrated cutting-edge technologies: computer vision for AR marker detection created a precise positioning system and player readiness indicator. WebSockets harnessed local WiFi, creating a high-performance multiplayer network.",
      //   },
      //   {
      //     list: "The result: BMX Olympic Showdown. Players were instantly immersed in virtual races, competing on AR tracks overlaid with millimetre precision. Our optimised netcode ensured smooth gameplay across multiple concurrent sessions.",
      //   },
      //   {
      //     list: "From concept to thousands of thrilled users in two months, we redefined rapid XR development. At Reality Scale, we transform time constraints into catalysts for innovation and pushing boundaries at Olympic speed.",
      //   },
      // ],
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },

    {
      name: "Mahindra Automotive",
      pageurl: "mahindra-project",
      projectHeading: "Mahindra",
      projectsecongheading: "Project",
      id: 1,
      img: mahindrasliderimage,
      thumbnail: mahindrathumbnailimage,
      video: "https://www.youtube.com/embed/6qrRj9pemRk?si=OWhT1RRuWKUcbqLg",
      title: "VR/AR",
      description: "Description for VR/AR Image 2",
      content: "Mahindra Automotive Project",
      heading: "Mahindra Automotive VR Configurator",
      subheading:
        "Pioneering VR showroom allowing customers to explore, customize, and interact with virtual cars.",
      productDetails: {
        bannerImage: youtholympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "",
        heading1: "Mahindra Automotive VR Configurator",
        heading2: "Project Overview",
        para1:
          "In the early days of virtual reality, Reality Scale embarked on a groundbreaking journey to transform the automotive retail experience. We were tasked with creating a fully immersive VR showroom for a leading car manufacturer, pushing the boundaries of what was possible with this emerging technology.",
        para2:
          "In the early days of virtual reality technology, we undertook an ambitious project to create a VR showroom for a leading automotive company. We aimed to develop an immersive, interactive environment where users could explore all car variants and models as if they were in a physical showroom. This project pushed the boundaries of VR technology at the time, challenging us to achieve high-quality rendering, intuitive controls, and realistic interactions within a virtual space.",
        impact: "Impact",
        impactberief:
          "This Automotive VR Project revolutionised how car manufacturers showcase their products, providing an experience that surpassed traditional showrooms. Its success triggered an industry-wide transformation, with our client integrating their entire lineup into VR and competitors racing to develop even more advanced solutions.",
        impactberiefone:
          "The project marked our entry into the enterprise space, laying the groundwork for our current solutions, including our flagship NeoReal projects. Despite a temporary setback due to the pandemic, enterprise VR is now resurging across multiple sectors.",
        impactberieftwo:
          "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "High-fidelity VR rendering of multiple car models and variants",
          },
          {
            list: "Intuitive single-controller interface for user interactions",
          },
          {
            list: "Secondary controller for sales consultant assistance",
          },
          {
            list: "Realistic car interactions (opening doors, adjusting seats, etc.)",
          },
          {
            list: "Dynamic car customisation (model selection, color changes)",
          },
          {
            list: "Complementary mobile application for users uncomfortable with VR movement",
          },
        ],

        technicallist: [
          {
            head: "Rendering Quality",
            list: "We overcame significant technical challenges to achieve high-quality rendering that accurately represented the cars' details and finishes.",
          },
          {
            head: "Intuitive Controls",
            list: " After extensive experimentation, we developed a single-controller interface that allowed users to navigate and interact with the virtual environment naturally.",
          },
          {
            head: "Realistic Interactions",
            list: "We successfully replicated real-world car interactions in VR, enabling users to open doors, sit in seats, adjust seating positions, and even open the sunroof.",
          },
          {
            head: "Customization Options",
            list: "Users could easily switch between different car models, variants, and colors, providing a comprehensive exploration experience.",
          },
          {
            head: "Sales Consultant Integration",
            list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
          },
          {
            head: "Scalable Car Framework",
            list: "We developed a flexible framework that allowed for easy addition of new car models to the system, ensuring long-term scalability.",
          },
          {
            head: "Accessibility Solution",
            list: " Recognizing that some users struggled with VR movement, we created a ctary omplemenmobile application to ensure the experience was accessible to all potential customers",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
    },
    {
      pageurl: "screen-craft",
      projectHeading: "Screen Craft",
      projectsecongheading: "",
      name: "screen Craft",
      type: "VR/AR",
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      img: screencraft,
      productDetails: {
        bannerImage: nexon,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
        ],
        paraHeading: "Screen Craft Project",
        heading1: "Scene Craft: Advanced Fabric Rendering Platform",
        heading2: "Project Overview",
        para1:
          "Scene Craft represents one of Reality Scale's most technologically advanced project. Born from the need for high-quality, cost-effective fabric rendering, this platform pushes the boundaries of what's possible in digital textile visualisation. By leveraging our proprietary hybrid rendering technology, Scene Craft offers an unparalleled combination of real-time interactivity and photorealistic output sets a new industry standard.",
        para2:
          "Scene Craft evolved from our initial WebGL solution, addressing limitations in render quality and high server costs of our real-time interior solutions. Leveraging our research in hybrid rendering, we developed Scene Craft as a SaaS platform for fabric and textile makers. This innovative approach combines real-time WebGL rendering for interactive adjustments with cloud-based high-quality rendering for final outputs, creating a more advanced and scalable product.",
        impact: "Impact",
        impactberief:
          "Scene Craft has revolutionised fabric rendering by combining real-time interactivity with photorealistic output at unprecedented resolutions. Its scalable, cost-effective approach makes high-quality visualisation accessible to businesses of all sizes, accelerating product development and enhancing marketing capabilities.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading: "",
        featurelist: [
          {
            list: "Combines WebGL for real-time manipulation with cloud-based high-quality rendering, offering unmatched flexibility.",
          },
          {
            list: "Produces renders up to 16K resolution, matching V-Ray quality with real-time adjustability.",
          },
          {
            list: "Offers various material types and adjustable properties for extensive customization.",
          },
          {
            list: "Accommodates multiple users simultaneously at reduced costs compared to traditional solutions.",
          },
          {
            list: "Allows scene uploads, user access management, and platform oversight.",
          },
          {
            list: "Enables quick scene adjustments with immediate visual feedback.",
          },
          {
            list: "Provides intuitive controls for texture uploads, property adjustments, and render initiation.",
          },
        ],

        technicallist: [
          {
            head: "Hybrid Rendering Pipeline:",
            list: " Integrated real-time WebGL and high-quality cloud-based rendering, solving synchronisation and data management challenges.",
          },
          {
            head: "Scalable Cloud Architecture:",
            list: "Built infrastructure handling multiple rendering requests cost-effectively.",
          },
          {
            head: "Real-Time to High-Quality:",
            list: " Developed system translating WebGL adjustments into high-quality render Parameters, ensuring preview-output consistency.",
          },
          {
            head: "Advanced Material System:",
            list: "Created a flexible system that accurately represents complex fabrics in real-time and high-quality renders.",
          },
          {
            head: "High-Resolution Rendering: ",
            list: "Engineered rendering up to 16K, balancing quality and rendering time.",
          },
          {
            head: "Scene Management System: ",
            list: "Implemented robust backend for efficient scene and user management.",
          },
          {
            head: "Cross-Platform Compatibility: ",
            list: "Ensured functionality across devices and browsers, overcoming WebGL and cloud integration challenges.",
          },
        ],
        slider2: [
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
        ],
      },
      // voiceoverheading: "Voice over scripts",
      // scriptheading: "",
      // scriptlist: [
      //   {
      //     list: "Imagine you're a fabric designer, eager to see your creation come to life, but physical setups and outsourced rendering are slow and expensive. What if you could produce lifelike renders in minutes, in any scene? We'd already created a WebGL solution, but good quality wasn't enough. We aim to deliver production-quality renders without breaking the bank.",
      //   },
      // ],
      // noteheading: "Notes:",
      // notes: [
      //   {
      //     list: "Picture this: You're a fabric designer eager to see your latest creation come to life. ",
      //   },
      //   {
      //     list: "But setting up a scene physically takes time and is expensive. ",
      //   },
      //   {
      //     list: "Asking a company to render your scene is also expensive and time taking.",
      //   },
      //   {
      //     list: "Imagine taking out reallife like renders in a few minutes in any scene of our choosing.",
      //   },
      //   {
      //     list: "We had already created a webgl based solution which could do this but the quality was “good”, aht that was not enough for it.",
      //   },
      //   {
      //     list: "We wanted to make a game-changing platform for fabric and textile makers.",
      //   },
      //   {
      //     list: "Delivering V-Ray quality renders without breaking the bank.",
      //   },
      //   {
      //     list: "That's when our previous research into hybrid rendering technology paid off. We fused real-time WebGL with cloud-based high-quality rendering, creating a solution that's both powerful and cost-effective. This is something we had done as R&D before for cars.",
      //   },
      //   {
      //     list: "With Scene Craft, users can upload textures, tweak properties, and see changes instantly. But here's the kicker: with a click, they can generate stunningly photorealistic renders at up to 16K resolution in minuties. It's like having a mini VFX studio at your fingertips.",
      //   },
      //   {
      //     list: "We continued. We built a robust admin system for easy scene management and user access control.",
      //   },
      //   {
      //     list: "Users can manually add their scenes to the system and have a set of predefined scenes to choose from.",
      //   },
      //   {
      //     list: "For the cherry on top, we added an advanced material system, giving users unprecedented control over their fabric's look and feel.",
      //   },
      //   {
      //     list: "Developing Scene Craft was like navigating uncharted waters. We faced countless unknowns, but our team's expertise and determination prevailed. The result? One of the most advanced and scalable fabric rendering solutions on the market.",
      //   },
      //   {
      //     list: "It's a testament to Reality Scale's commitment to innovation. We're setting the pace, one render at a time.",
      //   },
      // ],
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
  ],

  vr: [
    {
      pageurl: "disney-pyjama-ride",
      projectHeading: "Disney",
      projectsecongheading: "Pyjama Ride",
      name: "Disney Pyjama Ride",
      type: "VR/AR",
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide3,
          },
          {
            img: disneyslide4,
          },
          {
            img: disneyslide5,
          },
        ],
        paraHeading: "Disney Pyjama Ride",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      // voiceoverheading: "Voice over scripts",
      // scriptheading: "Script 1",
      // scriptlist: [
      //   {
      //     list: "Step into the world of Pyjama Ride, our thrilling VR project that turns virtual sledding into an unforgettable adventure for kids!",
      //   },
      //   {
      //     list: "Our team tackled the challenge of creating an intuitive control system that's easy for children to use, yet delivers an exhilarating experience. We developed a dynamic track system, allowing precise control over speed and shape to keep the excitement high.",
      //   },
      //   {
      //     list: "One of our proudest achievements is the vast, seamless world we built. Picture a multi-level mountain landscape filled with surprising events like collapsing rocks and flowing lava. It's an endless virtual roller coaster!",
      //   },
      //   {
      //     list: "But here's the real magic: our real-time recording system captures every thrilling moment, bringing the virtual experience into the real world.",
      //   },
      //   {
      //     list: "Pyjama Ride isn't just a game – it's a journey into the possibilities of VR. At Reality Scale, we're not just creating games; we're crafting dreams and making them virtually real.",
      //   },
      // ],
      // secondscriptheading: "Script 2",
      // secondscriptlist: [
      //   {
      //     list: "Developing Pyjama Ride was quite the roller coaster – pun intended. We set out to create a VR sledding game for kids, and boy, did we hit some bumps along the way.",
      //   },
      //   {
      //     list: "Our biggest challenge? Nailing those controls. We needed them simple enough for kids but still fun. Cue countless hours tweaking physics and chasing bugs – like when the sledge decided it wanted to be a rocket ship instead.",
      //   },
      //   {
      //     list: "Building a massive, seamless VR world nearly melted our machines. We got pretty creative with optimization just to keep the frame rate above slideshow levels.",
      //   },
      //   {
      //     list: "And don't get me started on the real-time event system. Syncing falling rocks and lava flows across the network? Let's just say it involved a lot of late-night debugging and energy drinks.",
      //   },
      //   {
      //     list: "In the end, we made something that kids enjoyed, even if it wasn't perfect. The real win was everything we learned about VR dev for young audiences. That knowledge? It's gold for our future projects.",
      //   },
      // ],
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      pageurl: "youth-olympics-project",
      projectHeading: "Youth",
      projectsecongheading: "Olympic Project",
      name: "Youth Olympics Project",
      type: "VR/AR",
      img: olympic,
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      parentname: "deepanshu",
      productDetails: {
        bannerImage: olympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "Youth Olympics Project",
        heading1: "BMX Olympic Showdown: AR Racing Reimagined",
        heading2: "Project Overview",
        para1:
          "BMX Olympic Showdown, an exhilarating multiplayer Augmented Reality racing game developed for the Winter Youth Olympics show floor. This project exemplifies our ability to create quick and fun experiences that engage and excite users in high-stakes environments using already existing technologies and at rapid speed.",
        para2:
          "The game was made to transform any floor into a virtual racing arena, allowing multiple players to compete in thrilling BMX races using iPad devices. The game combines the excitement of BMX racing with the immersive capabilities of XR and gives users an easy and competitive experience.",
        impact: "Impact",
        impactberief:
          "Olympic AR BMX demonstrates our capability to create engaging, accessible AR content for large-scale events rapidly. The game was enjoyed by thousands of participants during the Youth Olympics, bringing the excitement of BMX racing to life through the innovative use of existing XR technologies. This project showcases our expertise in swift development of impactful AR solutions that perform flawlessly in demanding, high-profile environments.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "Seamless multiplayer connectivity for real-time racing",
          },
          {
            list: "Intuitive controls optimised for iPad devices",
          },
          {
            list: "AR-powered course overlay on any physical floor",
          },
          {
            list: "High-quality animations and visual effects",
          },
          {
            list: "Quick-to-learn, challenging-to-master gameplay",
          },
        ],

        technicallist: [
          {
            head: "Efficient Multiplayer System:",
            list: "We utilised web sockets for seamless device connectivity, enabling multiple race sessions to run simultaneously on the show floor.",
          },
          {
            head: "Simple AR Synchronization:",
            list: "Our team implemented a straightforward AR scanning system to sync the game environment across all connected devices quickly.",
          },
          {
            head: "Low-Latency Action Communication:",
            list: " We created a streamlined system for transmitting and synchronising player actions across devices in real time.",
          },
          {
            head: "Flexible Session Management:",
            list: "Our solution allowed for easy setup of multiplayer sessions using iPads, facilitating quick turnover between participant groups",
          },
          {
            head: "Optimized AR Performance:",
            list: "The experience was fine-tuned to deliver smooth, engaging visuals and animations on iPad devices within a short development timeframe.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      // voiceoverheading: "Voice over scripts",
      // scriptheading: "Script 1",
      // scriptlist: [
      //   {
      //     list: "Imagine transforming any floor into an Olympic BMX racetrack with just a few iPads. That was our challenge for the Winter Youth Olympics, with only 60 days to deliver.",
      //   },
      //   {
      //     list: "Our mission: create a multiplayer augmented reality BMX racing game that is quick to learn, fun to play, and robust enough for thousands of users.",
      //   },
      //   {
      //     list: "We faced hurdles like syncing AR environments across devices and ensuring smooth multiplayer gameplay.",
      //   },
      //   {
      //     list: "Our approach was to leverage AR detection as both a positioning system and a player readiness indicator. We then utilised Web Sockets to create a local network of interconnected devices with the existing WiFi infrastructure. This combination of technologies resulted in a seamless multiplayer platform with precise spatial awareness and low-latency communication. ",
      //   },
      //   {
      //     list: "The result? BMX Olympic Showdown - an exhilarating AR racing experience that captivated thousands of participants. Players could jump into races within seconds, competing on virtual tracks overlaid on the physical floor.",
      //   },
      //   {
      //     list: "This is Reality Scale: where time constraints fuel innovation, not limit it.",
      //   },
      // ],
      // secondscriptheading: "Script 2",
      // secondscriptlist: [
      //   {
      //     list: "60 days to revolutionise Olympic entertainment with AR. Our challenge: transform any floor into a BMX racetrack using only iPads for the Winter Youth Olympics.",
      //   },
      //   {
      //     list: "We faced complex issues of spatial synchronisation and low-latency multiplayer functionality in a dynamic event environment. Our solution integrated cutting-edge technologies: computer vision for AR marker detection created a precise positioning system and player readiness indicator. WebSockets harnessed local WiFi, creating a high-performance multiplayer network.",
      //   },
      //   {
      //     list: "The result: BMX Olympic Showdown. Players were instantly immersed in virtual races, competing on AR tracks overlaid with millimetre precision. Our optimised netcode ensured smooth gameplay across multiple concurrent sessions.",
      //   },
      //   {
      //     list: "From concept to thousands of thrilled users in two months, we redefined rapid XR development. At Reality Scale, we transform time constraints into catalysts for innovation and pushing boundaries at Olympic speed.",
      //   },
      // ],
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },

    {
      name: "Mahindra Automotive",
      pageurl: "mahindra-project",
      projectHeading: "Mahindra",
      projectsecongheading: "Project",
      id: 1,
      img: mahindrasliderimage,
      thumbnail: mahindrathumbnailimage,
      video: "https://www.youtube.com/embed/6qrRj9pemRk?si=OWhT1RRuWKUcbqLg",
      title: "VR/AR",
      description: "Description for VR/AR Image 2",
      content: "Mahindra Automotive Project",
      heading: "Mahindra Automotive VR Configurator",
      subheading:
        "Pioneering VR showroom allowing customers to explore, customize, and interact with virtual cars.",
      productDetails: {
        bannerImage: youtholympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "",
        heading1: "Mahindra Automotive VR Configurator",
        heading2: "Project Overview",
        para1:
          "In the early days of virtual reality, Reality Scale embarked on a groundbreaking journey to transform the automotive retail experience. We were tasked with creating a fully immersive VR showroom for a leading car manufacturer, pushing the boundaries of what was possible with this emerging technology.",
        para2:
          "In the early days of virtual reality technology, we undertook an ambitious project to create a VR showroom for a leading automotive company. We aimed to develop an immersive, interactive environment where users could explore all car variants and models as if they were in a physical showroom. This project pushed the boundaries of VR technology at the time, challenging us to achieve high-quality rendering, intuitive controls, and realistic interactions within a virtual space.",
        impact: "Impact",
        impactberief:
          "This Automotive VR Project revolutionised how car manufacturers showcase their products, providing an experience that surpassed traditional showrooms. Its success triggered an industry-wide transformation, with our client integrating their entire lineup into VR and competitors racing to develop even more advanced solutions.",
        impactberiefone:
          "The project marked our entry into the enterprise space, laying the groundwork for our current solutions, including our flagship NeoReal projects. Despite a temporary setback due to the pandemic, enterprise VR is now resurging across multiple sectors.",
        impactberieftwo:
          "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "High-fidelity VR rendering of multiple car models and variants",
          },
          {
            list: "Intuitive single-controller interface for user interactions",
          },
          {
            list: "Secondary controller for sales consultant assistance",
          },
          {
            list: "Realistic car interactions (opening doors, adjusting seats, etc.)",
          },
          {
            list: "Dynamic car customisation (model selection, color changes)",
          },
          {
            list: "Complementary mobile application for users uncomfortable with VR movement",
          },
        ],

        technicallist: [
          {
            head: "Rendering Quality",
            list: "We overcame significant technical challenges to achieve high-quality rendering that accurately represented the cars' details and finishes.",
          },
          {
            head: "Intuitive Controls",
            list: " After extensive experimentation, we developed a single-controller interface that allowed users to navigate and interact with the virtual environment naturally.",
          },
          {
            head: "Realistic Interactions",
            list: "We successfully replicated real-world car interactions in VR, enabling users to open doors, sit in seats, adjust seating positions, and even open the sunroof.",
          },
          {
            head: "Customization Options",
            list: "Users could easily switch between different car models, variants, and colors, providing a comprehensive exploration experience.",
          },
          {
            head: "Sales Consultant Integration",
            list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
          },
          {
            head: "Scalable Car Framework",
            list: "We developed a flexible framework that allowed for easy addition of new car models to the system, ensuring long-term scalability.",
          },
          {
            head: "Accessibility Solution",
            list: " Recognizing that some users struggled with VR movement, we created a ctary omplemenmobile application to ensure the experience was accessible to all potential customers",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
    },
  ],

  automative: [
    {
      name: "Mahindra Automotive",
      pageurl: "mahindra-project",
      projectHeading: "Mahindra",
      projectsecongheading: "Project",
      id: 1,
      img: mahindrasliderimage,
      thumbnail: mahindrathumbnailimage,
      video: "https://www.youtube.com/embed/6qrRj9pemRk?si=OWhT1RRuWKUcbqLg",
      title: "VR/AR",
      description: "Description for VR/AR Image 2",
      content: "Mahindra Automotive Project",
      heading: "Mahindra Automotive VR Configurator",
      subheading:
        "Pioneering VR showroom allowing customers to explore, customize, and interact with virtual cars.",
      productDetails: {
        bannerImage: youtholympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "",
        heading1: "Mahindra Automotive VR Configurator",
        heading2: "Project Overview",
        para1:
          "In the early days of virtual reality, Reality Scale embarked on a groundbreaking journey to transform the automotive retail experience. We were tasked with creating a fully immersive VR showroom for a leading car manufacturer, pushing the boundaries of what was possible with this emerging technology.",
        para2:
          "In the early days of virtual reality technology, we undertook an ambitious project to create a VR showroom for a leading automotive company. We aimed to develop an immersive, interactive environment where users could explore all car variants and models as if they were in a physical showroom. This project pushed the boundaries of VR technology at the time, challenging us to achieve high-quality rendering, intuitive controls, and realistic interactions within a virtual space.",
        impact: "Impact",
        impactberief:
          "This Automotive VR Project revolutionised how car manufacturers showcase their products, providing an experience that surpassed traditional showrooms. Its success triggered an industry-wide transformation, with our client integrating their entire lineup into VR and competitors racing to develop even more advanced solutions.",
        impactberiefone:
          "The project marked our entry into the enterprise space, laying the groundwork for our current solutions, including our flagship NeoReal projects. Despite a temporary setback due to the pandemic, enterprise VR is now resurging across multiple sectors.",
        impactberieftwo:
          "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "High-fidelity VR rendering of multiple car models and variants",
          },
          {
            list: "Intuitive single-controller interface for user interactions",
          },
          {
            list: "Secondary controller for sales consultant assistance",
          },
          {
            list: "Realistic car interactions (opening doors, adjusting seats, etc.)",
          },
          {
            list: "Dynamic car customisation (model selection, color changes)",
          },
          {
            list: "Complementary mobile application for users uncomfortable with VR movement",
          },
        ],

        technicallist: [
          {
            head: "Rendering Quality",
            list: "We overcame significant technical challenges to achieve high-quality rendering that accurately represented the cars' details and finishes.",
          },
          {
            head: "Intuitive Controls",
            list: " After extensive experimentation, we developed a single-controller interface that allowed users to navigate and interact with the virtual environment naturally.",
          },
          {
            head: "Realistic Interactions",
            list: "We successfully replicated real-world car interactions in VR, enabling users to open doors, sit in seats, adjust seating positions, and even open the sunroof.",
          },
          {
            head: "Customization Options",
            list: "Users could easily switch between different car models, variants, and colors, providing a comprehensive exploration experience.",
          },
          {
            head: "Sales Consultant Integration",
            list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
          },
          {
            head: "Scalable Car Framework",
            list: "We developed a flexible framework that allowed for easy addition of new car models to the system, ensuring long-term scalability.",
          },
          {
            head: "Accessibility Solution",
            list: " Recognizing that some users struggled with VR movement, we created a ctary omplemenmobile application to ensure the experience was accessible to all potential customers",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
    },
    {
      name: "Renault Automotive",
      pageurl: "renault-project",
      projectHeading: "Renault",
      projectsecongheading: "Project",
      id: 1,
      img: "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/reanult2.jpg",
      thumbnail:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/renault1.jpg",
      video:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/renault.mp4",
      title: "VR/AR",
      description: "Description for VR/AR Image 2",
      content: "Renault Automotive Project",
      heading: "Renault - Cloud Streamed Automotive Configurator",
      subheading:
        "Cloud-streamed car configurator letting users explore Renault models remotely.",
      productDetails: {
        bannerImage: youtholympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "",
        heading1: "Renault - Cloud Streamed Automotive Configurator",
        heading2: "Project Overview",
        para1:
          "Renault Reality Streamed Configurator represents a groundbreaking leap in automotive digital retail. Building on our expertise in cloud gaming and previous automotive projects, we developed India's first cloud-streamed car configurator, setting a new benchmark in the industry. This innovative solution showcases our ability to push technological boundaries and deliver cutting-edge solutions that transform how customers interact with automotive products online.",
        para2:
          "The Renault Reality Streamed Configurator is a state-of-the-art, cloud-based platform that allows users to explore and customize Renault vehicles in photorealistic quality from any internet-connected device. Leveraging our proprietary WebRTC-based streaming solution, we developed a system capable of handling over 10,000 concurrent users while maintaining high visual fidelity and zero loading times. This project showcased multiple Renault models and incorporated advanced features and interactions that elevate the car exploration experience to new heights.",
        impact: "Impact",
        impactberief:
          "The Renault Reality Streamed Configurator revolutionised automotive digital experiences, earning Renault multiple awards, including the IDMA Gold Medal for Best Branded Content. As India's first cloud-streamed car configurator, Renault has positioned itself at the forefront of automotive innovation, significantly expanding its digital reach and redefining customer engagement. The platform's scalable, cost-efficient architecture allowed Renault to serve thousands of concurrent users, demonstrating the power of cloud-based solutions in automotive retail.",
        impactberiefone:
          "This project laid the foundation for Reality Scale's future innovations, including our NeoReal platform. By successfully merging cloud gaming technology with automotive visualisation, we solved our client's immediate challenges and pioneered a new direction for the entire industry. The configurator's success set a new benchmark for digital automotive experiences, influencing future developments and solidifying Reality Scale's position as a leader in innovative digital visualisation solutions.",
        // impactberieftwo:
        //   "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
        featureheading: "Key Feature",
        technical: "Project Highlights:",
        // technicalsubheading:
        //   "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "Cloud-streamed, photorealistic 3D car models with zero loading time",
          },
          {
            list: "Comprehensive exploration of all variants and colors for multiple Renault models",
          },
          {
            list: "Intuitive and user-friendly interface for easy navigation and customization",
          },
          {
            list: "Advanced visualization of car features, including seat configurations and smart technologies",
          },
          {
            list: "Scalable architecture supporting 10,000+ concurrent users",
          },
          {
            list: "Optimized for high performance and low operational costs",
          },
          {
            list: "Seamless accessibility from any internet-connected device",
          },
        ],

        technicallist: [
          {
            // head: "Rendering Quality",
            list: "Pioneered India's first cloud-streamed car configurator.",
          },
          {
            // head: "Intuitive Controls",
            list: "Expanded and optimised our existing WebRTC-based streaming solution for automotive use.",
          },
          {
            // head: "Realistic Interactions",
            list: "Developed a highly scalable system capable of supporting massive concurrent user loads.",
          },
          {
            // head: "Customization Options",
            list: "Implemented innovative interactions and visualisations to showcase car features in unprecedented detail.",
          },
          {
            // head: "Sales Consultant Integration",
            list: "Optimised the entire system for maximum visual quality at minimal operational cost.",
          },
          {
            // head: "Scalable Car Framework",
            list: "Successfully integrated and showcased multiple Renault car models within the platform.",
          },
          {
            // head: "Accessibility Solution",
            list: " Created a foundation for future expansion and adaptation of the technology.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
    },
    {
      name: "Honda Automotive",
      pageurl: "honda-project",
      projectHeading: "Honda",
      projectsecongheading: "Project",
      id: 1,
      img: "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/honda2.jpg",
      thumbnail:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/honda1.jpg",
      video: "https://realityscalebucket.s3.eu-north-1.amazonaws.com/honda.mp4",
      title: "VR/AR",
      description: "Description for VR/AR Image 2",
      content: "Honda Automotive Project",
      heading: "Honda Automotive Digital Brochure",
      subheading:
        "Interactive vehicle configurator empowering dealers to showcase cars through touchscreen displays.",
      productDetails: {
        bannerImage: youtholympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "",
        heading1: "Honda Automotive Digital Brochure",
        heading2: "Project Overview",
        para1:
          "The Remote Controlled Configurator is a comprehensive digital ecosystem that enables dealerships to showcase their entire vehicle lineup on digital signage. Controlled by sales consultants via a custom mobile app, this system redefines customer exploration of vehicles, setting a new standard in interactive product presentation.",
        para2:
          "The project emerged from a critical business need to revolutionize the traditional car buying experience. With showroom space becoming more expensive and customers seeking more flexible methods to experience automobiles, our VR system provided a scalable, cost-effective alternative that could be deployed across various locations. This invention not only solved immediate space restrictions, but also gave significant data insights into consumer preferences and behavior patterns during the car-buying process.",
        impact: "Impact",
        impactberief:
          "This Remote Controlled Configurator revolutionized automotive retail by seamlessly blending digital innovation with showroom engagement. It eliminated inventory constraints while enhancing product showcase capabilities across over 200 dealerships. The project streamlined sales processes and provided valuable customer interaction data, enabling informed decision-making in product development and marketing.",
        impactberiefone:
          "The success of this project, including the seamless integration of Honda's entire catalogue (nine cars, 50 variants, and hundreds of interactions), laid the groundwork for our future cloud-based Neo-Real platform. It cemented Reality Scale's position as a leader in immersive automotive visualization, showcasing our ability to deliver complex, scalable solutions that address real-world business challenges and our commitment to continuous innovation.",
        // impactberieftwo:
        //   "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team leveraged existing technologies to deliver this project rapidly:",
        featurelist: [
          {
            list: "High-fidelity digital brochure showcasing entire vehicle lineup and variants",
          },
          {
            list: "Remote control functionality via dedicated mobile application",
          },
          {
            list: "Real-time synchronization between mobile app and primary display",
          },
          {
            list: "Interactive elements demonstrating vehicle technologies and dashboard features",
          },
          {
            list: "Modular design adaptable to various car models",
          },
          {
            list: "Comprehensive data collection and analytics",
          },
          {
            list: "Automated distribution system for over 200 dealerships",
          },
        ],

        technicallist: [
          {
            head: "Unified Codebase:",
            list: "Developed a single, efficient codebase powering both the configurator and mobile app.",
          },
          {
            head: "Modular Design:",
            list: "Created a forward-thinking approach allowing easy adaptation to different car models and future requirements.",
          },
          {
            head: "WebSocket Integration:",
            list: "Implemented WebSocket technology to establish robust links between mobile devices and primary displays.",
          },
          {
            head: "CI/CD Pipeline:",
            list: "Engineered an automated distribution system for real-time updates across hundreds of dealerships.",
          },
          {
            head: "Sales Consultant Integration",
            list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
          },
          {
            head: "Advanced Analytics:",
            list: "Implemented sophisticated data collection mechanisms for valuable user insights.",
          },
          {
            head: "Photorealistic Rendering:",
            list: " Achieved stunning visual fidelity, showcasing vehicles in exquisite detail.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
    },
  ],

  archite: [
    {
      pageurl: "screen-craft",
      projectHeading: "Screen",
      projectsecongheading: "Craft",
      name: "screen Craft",
      type: "VR/AR",
      video: "https://www.youtube.com/embed/deu0PnGsZgg?si=cWgTyLAT4gT3q6Tt",
      img: screencraft,
      productDetails: {
        bannerImage: nexon,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
        ],
        paraHeading: "Screen Craft Project",
        heading1: "Scene Craft: Advanced Fabric Rendering Platform",
        heading2: "Project Overview",
        para1:
          "Scene Craft represents one of Reality Scale's most technologically advanced project. Born from the need for high-quality, cost-effective fabric rendering, this platform pushes the boundaries of what's possible in digital textile visualisation. By leveraging our proprietary hybrid rendering technology, Scene Craft offers an unparalleled combination of real-time interactivity and photorealistic output sets a new industry standard.",
        para2:
          "Scene Craft evolved from our initial WebGL solution, addressing limitations in render quality and high server costs of our real-time interior solutions. Leveraging our research in hybrid rendering, we developed Scene Craft as a SaaS platform for fabric and textile makers. This innovative approach combines real-time WebGL rendering for interactive adjustments with cloud-based high-quality rendering for final outputs, creating a more advanced and scalable product.",
        impact: "Impact",
        impactberief:
          "Scene Craft has revolutionised fabric rendering by combining real-time interactivity with photorealistic output at unprecedented resolutions. Its scalable, cost-effective approach makes high-quality visualisation accessible to businesses of all sizes, accelerating product development and enhancing marketing capabilities.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading: "",
        featurelist: [
          {
            list: "Combines WebGL for real-time manipulation with cloud-based high-quality rendering, offering unmatched flexibility.",
          },
          {
            list: "Produces renders up to 16K resolution, matching V-Ray quality with real-time adjustability.",
          },
          {
            list: "Offers various material types and adjustable properties for extensive customization.",
          },
          {
            list: "Accommodates multiple users simultaneously at reduced costs compared to traditional solutions.",
          },
          {
            list: "Allows scene uploads, user access management, and platform oversight.",
          },
          {
            list: "Enables quick scene adjustments with immediate visual feedback.",
          },
          {
            list: "Provides intuitive controls for texture uploads, property adjustments, and render initiation.",
          },
        ],

        technicallist: [
          {
            head: "Hybrid Rendering Pipeline:",
            list: " Integrated real-time WebGL and high-quality cloud-based rendering, solving synchronisation and data management challenges.",
          },
          {
            head: "Scalable Cloud Architecture:",
            list: "Built infrastructure handling multiple rendering requests cost-effectively.",
          },
          {
            head: "Real-Time to High-Quality:",
            list: " Developed system translating WebGL adjustments into high-quality render Parameters, ensuring preview-output consistency.",
          },
          {
            head: "Advanced Material System:",
            list: "Created a flexible system that accurately represents complex fabrics in real-time and high-quality renders.",
          },
          {
            head: "High-Resolution Rendering: ",
            list: "Engineered rendering up to 16K, balancing quality and rendering time.",
          },
          {
            head: "Scene Management System: ",
            list: "Implemented robust backend for efficient scene and user management.",
          },
          {
            head: "Cross-Platform Compatibility: ",
            list: "Ensured functionality across devices and browsers, overcoming WebGL and cloud integration challenges.",
          },
        ],
        slider2: [
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
          {
            img: screencraft,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
  ],

  gaming: [
    {
      name: "Disney Game Project",
      type: "     GAMING",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "ENVATO MARKET",
        brand: "Disney Game",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
        ],
        paraHeading: "Disney Game Project",
        para1:
          "The project developed a multiplayer VR game based on the Unreal engine. The project management consists of planning, resources, and execution. The game has been examined and developed to offer a flawless experience to the players and viewers. To make it more alluring it has been designed to work on the animation and sound effects. Based on logic and physics, it is designed in a way that is enjoyable and full of challenges. Moreover, it functions on the user considering the interaction and creates a friendly environment for the child.   ",
        para2:
          "The game ensures the safety of the child and follows the stipulated relevant regulatory standards. The designed game is supposed to be broadcast live during a Disney children's show. Stipulation of the crucial and innovative technology has been done for live broadcasting of the gameplay of the show.",
        slider2: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "GAMING",
      img: image8,
      productDetails: {
        bannerImage: image8,
        client: "ENVATO MARKET",
        brand: "Youth Olympics",
        slider1Image: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
        paraHeading: "Youth Olympics Project",
        para1:
          "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid.  ",
        para2:
          "The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
        slider2: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "GAMING",
      img: image8,
      productDetails: {
        bannerImage: image8,
        client: "ENVATO MARKET",
        brand: "Youth Olympics",
        slider1Image: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
        paraHeading: "Youth Olympics Project",
        para1:
          "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid.  ",
        para2:
          "The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
        slider2: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
  ],

  uniquedata: [
    {
      blogimage:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/blogImages/XR+BLOG+IMAGES/image1.png",
      url: "/blog/marketing-evoltuion",
      head: unique.head1,
      para: unique.para1,
      img: unique.img1,
      chart: chart,
      heading: "Introduction",
      headparaone:
        "The digital marketing landscape is undergoing a profound transformation, with global spending on digital ads projected to surge from $614 billion in 2024 to $870.85 billion by 2027. This explosive growth brings both opportunities and challenges. While digital channels offer unparalleled reach, many campaigns struggle with effectiveness, accurate measurement, and standing out in a crowded space.",
      headonetwo:
        "Reality Scale emerges as a potential game-changer in this evolving landscape. By creating immersive, interactive experiences, Reality Scale aims to redefine customer engagement and brand interaction in the digital age.",
      headonethree:
        "This article explores the current state of digital marketing, its challenges, and how Reality Scale's innovative approach could shape the future of digital advertising. Join us as we delve into a new frontier where interactive 3D experiences and intelligent user interactions are poised to transform brand-consumer relationships, setting new standards for effectiveness, engagement, and data-driven insights.",
      headingtwo: "The Current State of Digital Marketing",
      currentparaone:
        "The digital marketing industry has transformed into a global powerhouse, with ad spending projected to grow substantially from 2017 to 2028. This growth reflects shifting consumer behavior and increasingly sophisticated marketing techniques. Tech giants like Google and Meta dominate, with combined ad revenues exceeding $368 billion in 2023. As the industry evolves, companies are not just increasing spending but adopting smarter, data-driven strategies and innovative platforms to connect more meaningfully with consumers.",
      headingthree: "Evolution of Digital Marketing Techniques",
      digitalmarketingparaone:
        "The evolution of digital marketing techniques has been rapid and transformative, mirroring the fast-paced changes in technology and consumer behavior. In the early days of digital marketing, businesses relied heavily on what we now consider traditional methods:",
      marketinglistone: [
        {
          list: "Display Ads: Banner advertisements on websites",
        },
        {
          list: "Search Engine Advertising: Sponsored links in search results",
        },
        {
          list: "Email Marketing: Direct communication with customers via email",
        },
      ],
      marketingparatwo:
        "While these techniques are still used today, they represent just the beginning of the digital marketing toolkit. The modern marketer's arsenal has expanded significantly:",
      marketinglisttwo: [
        {
          list: "Social Media Marketing: Building brand communities on platforms like Facebook, Instagram, and Twitter",
        },
        {
          list: "Content Marketing: Creating valuable blog posts, videos, and podcasts to attract and engage audiences",
        },
        {
          list: "Influencer Partnerships: Collaborating with social media personalities to reach their loyal followings",
        },
        {
          list: "Programmatic Advertising: Using AI to automate ad buying for more efficient and targeted campaigns",
        },
      ],
      marketingparathree:
        "Recent years have witnessed two major shifts in digital marketing: data-driven personalization and the rise of short-form video content. Marketers now use consumer data to create tailored experiences, while platforms like TikTok and Instagram Reels cater to decreasing attention spans. These trends reflect the industry's adaptation to evolving consumer behaviors and technological advancements.",
      techheading: "Emerging Technologies",
      techparaone:
        "Emerging technologies are reshaping digital marketing. While AR and VR have shown promise, their widespread adoption has been limited by technical and accessibility challenges. Cloud-based immersive 3D experiences are emerging as a more versatile alternative, offering engaging brand interactions without specialized hardware or software.",
      techparatwo:
        "These cloud-based experiences combine the immersion of AR/VR with the accessibility of web platforms, allowing users to explore products and environments in 3D space directly from browsers or mobile devices. This approach overcomes many barriers associated with AR and VR, making it more practical for widespread marketing applications.",
      techparathree:
        "The evolution of digital marketing reflects a shift towards interactive, personalized, and data-driven approaches. Cloud-based immersive 3D experiences represent a significant step in this direction, offering a balance of engagement, accessibility, and scalability that positions them as a potentially transformative force in digital marketing.",

      conclusiontext: "Conclusion",
      conclusionparaone:
        "In essence, Reality Scale is trying to bridge the gap between digital marketing and real-world product experiences. ",
      conclusionparatwo:
        "As we look to the future, it's clear that companies that leverage tools like Reality Scale will have a significant advantage. They'll be able to create more engaging marketing content, gather more meaningful data, and ultimately, build stronger connections with their audience. ",
      conclusionparathree:
        "As consumer expectations continue to evolve, Reality Scale's immersive experiences offer a glimpse into the future of digital marketing - one where interactivity, personalization, and data-driven insights converge to create truly impactful brand experiences.",
      Landscapeheading: "Challenges in the Digital Marketing Landscape",
      landscapepara:
        "Despite its rapid growth and apparent effectiveness, digital marketing faces several significant challenges that marketers must navigate:",
      landscapelist: [
        {
          heading: "Questionable Effectiveness",
          para: "Many campaigns don't deliver promised results. eBay's experiment revealed their Google search ads were losing 63 cents per dollar spent, with traffic shifting to organic results when ads were halted.",
        },
        {
          heading: "Tricky ROI Measurement",
          para: "The selection effect makes it difficult to determine if marketing efforts are bringing in new customers or reaching existing ones.",
        },
        {
          heading: "Data Overload and Lack of Actionable Insights",
          para: "Companies collect vast amounts of data but struggle to translate it into meaningful actions.",
        },
        {
          heading: "Ad Fatigue and Banner Blindness",
          para: "Consumers increasingly ignore digital ads, making it harder to capture attention.",
        },
        {
          heading: "The Challenge of Organic Reach",
          para: "Social media algorithm changes have reduced organic reach, forcing companies to rely more on paid advertising.",
        },
        {
          heading: "Content Saturation",
          para: "The overwhelming amount of content makes it challenging to stand out and truly engage audiences.",
        },
      ],
      landscapeparatwo:
        "These challenges highlight the need for innovative solutions in digital marketing. As we'll explore in the next section, Reality Scale's approach to creating immersive, interactive experiences addresses many of these issues, offering a new paradigm for effective digital marketing in an increasingly complex landscape.",
      pathforward:
        "The Path Forward: Balancing Art and Science with Reality Scale",
      pathforward2: "Key features of Reality Scale's approach include:",
      pathforwardparaone:
        "Reality Scale emerges as a transformative force in digital marketing, addressing industry challenges through immersive, interactive experiences. By creating engaging, cloud-based solutions that range from 360-degree product views to fully immersive virtual environments, Reality Scale offers users unprecedented ways to interact with products and brands. This approach not only captivates consumers but also provides marketers with invaluable insights, potentially redefining the digital marketing landscape.",

      pathforwardparatwo:
        "As we navigate this complex digital landscape, it's clear that success in digital marketing requires more than just significant investment or technological prowess. ",
      pathforwardparathree:
        "Reality Scale represents the future of digital marketing by combining strategic thinking with cutting-edge technology. Their approach strikes a balance between leveraging advanced techniques and maintaining the human touch essential for effective communication. ",
      pathforwardparafour:
        "By providing these immersive, interactive experiences, Reality Scale addresses several key challenges in digital marketing:",
      pathforwardparafifth:
        "As we delve deeper into Reality Scale's applications and benefits in the following sections, it becomes clear that this technology isn't just an incremental improvement in digital marketing – it represents a paradigm shift in how brands can connect with and understand their audiences in the digital age.",

      pathforwardlist: [
        {
          list: "Interactive 3D Experiences: Detailed, customizable 3D product models for immersive user exploration.",
        },
        {
          list: "Cloud-Based Technology: Rich experiences accessible across devices without specialized hardware.",
        },
        {
          list: "Data-Rich Interactions: Valuable insights from user interactions inform marketing strategies.",
        },
        {
          list: "Seamless Integration: Easy incorporation into existing digital marketing channels.",
        },
        {
          list: "Scalability and Flexibility: Adaptable solutions for businesses of all sizes across various industries.",
        },
      ],
      pathforwardlisttwo: [
        {
          heading: "Overcoming Ad Fatigue:",
          para: "Reality Scale's interactive 3D experiences captivate users in ways traditional ads cannot. By offering engaging, immersive content, these experiences effectively combat ad fatigue and banner blindness, ensuring brand messages cut through the noise of oversaturated digital spaces.",
        },
        {
          heading: "Improving ROI Measurement:",
          para: "Through detailed tracking of user interactions within 3D environments, Reality Scale provides unprecedented insights into consumer engagement. This granular data allows for more accurate ROI assessment, moving beyond simplistic metrics to truly understand the impact of marketing efforts.",
        },
        {
          heading: "Delivering Actionable Insights:",
          para: "The rich data gathered from user interactions offers concrete, actionable insights that can inform product development and marketing strategies.",
        },
        {
          heading: "Enhancing Organic Reach:",
          para: "These interactive experiences are more likely to be shared and talked about, potentially improving organic reach on social platforms.",
        },
        {
          heading: "Standing Out in a Saturated Market:",
          para: "In a sea of static content, Reality Scale's immersive experiences offer a unique and memorable way for brands to differentiate themselves.",
        },
      ],

      actionmainheading: "Reality Scale in Action",
      actionheading:
        "Automotive Industry Example: Transforming Car Buying Experiences",
      actionparaone:
        "One of Reality Scale's most notable successes comes from the automotive sector. A leading car manufacturer implemented Reality Scale's cloud-based 3D configurator, allowing potential customers to explore and customize vehicles in unprecedented detail.",
      actionparatwo:
        "This immersive experience not only enhanced customer engagement but also provided the manufacturer with valuable data on consumer preferences, directly influencing product development and marketing strategies.",
      actionparathree:
        "Importantly, Reality Scale's technology offers companies a powerful testbed for product development. Before even launching a product, businesses can use these immersive experiences to gather feedback, test features, and refine their offerings based on real user interactions. This capability provides an infinitely possible virtual environment to test products before their release, significantly reducing risks and improving market fit.",
      actionshortheading: "Key features and outcomes:",

      actionapplicationheading: "Applications in Other Sectors",

      actionapplicationlist: [
        {
          heading: "Banner Ads with Direct Experience Access",
          para: "Reality Scale transformed traditional banner ads into gateways for immersive experiences. For instance, a luxury watch brand created banner ads that instantly launched a 3D interactive model of their latest timepiece when clicked.",
        },
        {
          heading: "Virtual Company Museums",
          para: "A century-old manufacturing company created a virtual museum using Reality Scale's technology, telling its rich history through an interactive 3D environment. This chronological journey through company milestones integrated archival photos and videos within the 3D space.",
        },
        {
          heading: "Real Estate: Virtual Property Tours",
          para: "In the real estate sector, Reality Scale's technology has been used to create immersive virtual property tours, allowing potential buyers to explore homes from anywhere in the world. These tours include detailed 3D models of properties, customizable interiors, and virtual staging options.",
        },
      ],
      actionlist: [
        {
          list: "Interactive 3D Models: Users could view cars from every angle, open doors, and examine interiors.",
        },
        {
          list: "Real-time Customization: Customers could change colors, add accessories, and see updates instantly.",
        },
        {
          list: "Data Collection: The company gained insights into popular color choices, most-viewed features, and user engagement patterns.",
        },
        {
          list: "Increased Engagement: Average time spent on the website increased by 230%, with a 45% boost in lead generation.",
        },
      ],
      actioninnovativeheading: "Other Innovative Applications",
      actioninnovativelist: [
        {
          list: "FMCG Product Launches: Interactive 3D worlds and games for new product exploration.",
        },
        {
          list: "Educational Institutions: Virtual campus tours and sample lectures.",
        },
        {
          list: "Travel and Hospitality: Immersive hotel room and property previews.",
        },
      ],
    },
    {
      blogimage:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/blogImages/XR+BLOG+IMAGES/image3.png",
      url: "/blog/exploringxr",
      head: unique.head2,
      para: unique.para2,
      img: unique.img1,
      chart: chart,
      heading: "Introduction",
      headparaone:
        "The digital marketing landscape is undergoing a profound transformation, with global spending on digital ads projected to surge from $614 billion in 2024 to $870.85 billion by 2027. This explosive growth brings both opportunities and challenges. While digital channels offer unparalleled reach, many campaigns struggle with effectiveness, accurate measurement, and standing out in a crowded space.",
      headonetwo:
        "Reality Scale emerges as a potential game-changer in this evolving landscape. By creating immersive, interactive experiences, Reality Scale aims to redefine customer engagement and brand interaction in the digital age.",
      headonethree:
        "This article explores the current state of digital marketing, its challenges, and how Reality Scale's innovative approach could shape the future of digital advertising. Join us as we delve into a new frontier where interactive 3D experiences and intelligent user interactions are poised to transform brand-consumer relationships, setting new standards for effectiveness, engagement, and data-driven insights.",
      headingtwo: "The Current State of Digital Marketing",
      currentparaone:
        "The digital marketing industry has transformed into a global powerhouse, with ad spending projected to grow substantially from 2017 to 2028. This growth reflects shifting consumer behavior and increasingly sophisticated marketing techniques. Tech giants like Google and Meta dominate, with combined ad revenues exceeding $368 billion in 2023. As the industry evolves, companies are not just increasing spending but adopting smarter, data-driven strategies and innovative platforms to connect more meaningfully with consumers.",
      headingthree: "Evolution of Digital Marketing Techniques",
      digitalmarketingparaone:
        "The evolution of digital marketing techniques has been rapid and transformative, mirroring the fast-paced changes in technology and consumer behavior. In the early days of digital marketing, businesses relied heavily on what we now consider traditional methods:",
      marketinglistone: [
        {
          list: "Display Ads: Banner advertisements on websites",
        },
        {
          list: "Search Engine Advertising: Sponsored links in search results",
        },
        {
          list: "Email Marketing: Direct communication with customers via email",
        },
      ],
      marketingparatwo:
        "While these techniques are still used today, they represent just the beginning of the digital marketing toolkit. The modern marketer's arsenal has expanded significantly:",
      marketinglisttwo: [
        {
          list: "Social Media Marketing: Building brand communities on platforms like Facebook, Instagram, and Twitter",
        },
        {
          list: "Content Marketing: Creating valuable blog posts, videos, and podcasts to attract and engage audiences",
        },
        {
          list: "Influencer Partnerships: Collaborating with social media personalities to reach their loyal followings",
        },
        {
          list: "Programmatic Advertising: Using AI to automate ad buying for more efficient and targeted campaigns",
        },
      ],
      marketingparathree:
        "Recent years have witnessed two major shifts in digital marketing: data-driven personalization and the rise of short-form video content. Marketers now use consumer data to create tailored experiences, while platforms like TikTok and Instagram Reels cater to decreasing attention spans. These trends reflect the industry's adaptation to evolving consumer behaviors and technological advancements.",
      techheading: "Emerging Technologies",
      techparaone:
        "Emerging technologies are reshaping digital marketing. While AR and VR have shown promise, their widespread adoption has been limited by technical and accessibility challenges. Cloud-based immersive 3D experiences are emerging as a more versatile alternative, offering engaging brand interactions without specialized hardware or software.",
      techparatwo:
        "These cloud-based experiences combine the immersion of AR/VR with the accessibility of web platforms, allowing users to explore products and environments in 3D space directly from browsers or mobile devices. This approach overcomes many barriers associated with AR and VR, making it more practical for widespread marketing applications.",
      techparathree:
        "The evolution of digital marketing reflects a shift towards interactive, personalized, and data-driven approaches. Cloud-based immersive 3D experiences represent a significant step in this direction, offering a balance of engagement, accessibility, and scalability that positions them as a potentially transformative force in digital marketing.",

      conclusiontext: "Conclusion",
      conclusionparaone:
        "In essence, Reality Scale is trying to bridge the gap between digital marketing and real-world product experiences. ",
      conclusionparatwo:
        "As we look to the future, it's clear that companies that leverage tools like Reality Scale will have a significant advantage. They'll be able to create more engaging marketing content, gather more meaningful data, and ultimately, build stronger connections with their audience. ",
      conclusionparathree:
        "As consumer expectations continue to evolve, Reality Scale's immersive experiences offer a glimpse into the future of digital marketing - one where interactivity, personalization, and data-driven insights converge to create truly impactful brand experiences.",
      Landscapeheading: "Challenges in the Digital Marketing Landscape",
      landscapepara:
        "Despite its rapid growth and apparent effectiveness, digital marketing faces several significant challenges that marketers must navigate:",
      landscapelist: [
        {
          heading: "Questionable Effectiveness",
          para: "Many campaigns don't deliver promised results. eBay's experiment revealed their Google search ads were losing 63 cents per dollar spent, with traffic shifting to organic results when ads were halted.",
        },
        {
          heading: "Tricky ROI Measurement",
          para: "The selection effect makes it difficult to determine if marketing efforts are bringing in new customers or reaching existing ones.",
        },
        {
          heading: "Data Overload and Lack of Actionable Insights",
          para: "Companies collect vast amounts of data but struggle to translate it into meaningful actions.",
        },
        {
          heading: "Ad Fatigue and Banner Blindness",
          para: "Consumers increasingly ignore digital ads, making it harder to capture attention.",
        },
        {
          heading: "The Challenge of Organic Reach",
          para: "Social media algorithm changes have reduced organic reach, forcing companies to rely more on paid advertising.",
        },
        {
          heading: "Content Saturation",
          para: "The overwhelming amount of content makes it challenging to stand out and truly engage audiences.",
        },
      ],
      landscapeparatwo:
        "These challenges highlight the need for innovative solutions in digital marketing. As we'll explore in the next section, Reality Scale's approach to creating immersive, interactive experiences addresses many of these issues, offering a new paradigm for effective digital marketing in an increasingly complex landscape.",
      pathforward:
        "The Path Forward: Balancing Art and Science with Reality Scale",
      pathforward2: "Key features of Reality Scale's approach include:",
      pathforwardparaone:
        "Reality Scale emerges as a transformative force in digital marketing, addressing industry challenges through immersive, interactive experiences. By creating engaging, cloud-based solutions that range from 360-degree product views to fully immersive virtual environments, Reality Scale offers users unprecedented ways to interact with products and brands. This approach not only captivates consumers but also provides marketers with invaluable insights, potentially redefining the digital marketing landscape.",

      pathforwardparatwo:
        "As we navigate this complex digital landscape, it's clear that success in digital marketing requires more than just significant investment or technological prowess. ",
      pathforwardparathree:
        "Reality Scale represents the future of digital marketing by combining strategic thinking with cutting-edge technology. Their approach strikes a balance between leveraging advanced techniques and maintaining the human touch essential for effective communication. ",
      pathforwardparafour:
        "By providing these immersive, interactive experiences, Reality Scale addresses several key challenges in digital marketing:",
      pathforwardparafifth:
        "As we delve deeper into Reality Scale's applications and benefits in the following sections, it becomes clear that this technology isn't just an incremental improvement in digital marketing – it represents a paradigm shift in how brands can connect with and understand their audiences in the digital age.",

      pathforwardlist: [
        {
          list: "Interactive 3D Experiences: Detailed, customizable 3D product models for immersive user exploration.",
        },
        {
          list: "Cloud-Based Technology: Rich experiences accessible across devices without specialized hardware.",
        },
        {
          list: "Data-Rich Interactions: Valuable insights from user interactions inform marketing strategies.",
        },
        {
          list: "Seamless Integration: Easy incorporation into existing digital marketing channels.",
        },
        {
          list: "Scalability and Flexibility: Adaptable solutions for businesses of all sizes across various industries.",
        },
      ],
      pathforwardlisttwo: [
        {
          heading: "Overcoming Ad Fatigue:",
          para: "Reality Scale's interactive 3D experiences captivate users in ways traditional ads cannot. By offering engaging, immersive content, these experiences effectively combat ad fatigue and banner blindness, ensuring brand messages cut through the noise of oversaturated digital spaces.",
        },
        {
          heading: "Improving ROI Measurement:",
          para: "Through detailed tracking of user interactions within 3D environments, Reality Scale provides unprecedented insights into consumer engagement. This granular data allows for more accurate ROI assessment, moving beyond simplistic metrics to truly understand the impact of marketing efforts.",
        },
        {
          heading: "Delivering Actionable Insights:",
          para: "The rich data gathered from user interactions offers concrete, actionable insights that can inform product development and marketing strategies.",
        },
        {
          heading: "Enhancing Organic Reach:",
          para: "These interactive experiences are more likely to be shared and talked about, potentially improving organic reach on social platforms.",
        },
        {
          heading: "Standing Out in a Saturated Market:",
          para: "In a sea of static content, Reality Scale's immersive experiences offer a unique and memorable way for brands to differentiate themselves.",
        },
      ],

      actionmainheading: "Reality Scale in Action",
      actionheading:
        "Automotive Industry Example: Transforming Car Buying Experiences",
      actionparaone:
        "One of Reality Scale's most notable successes comes from the automotive sector. A leading car manufacturer implemented Reality Scale's cloud-based 3D configurator, allowing potential customers to explore and customize vehicles in unprecedented detail.",
      actionparatwo:
        "This immersive experience not only enhanced customer engagement but also provided the manufacturer with valuable data on consumer preferences, directly influencing product development and marketing strategies.",
      actionparathree:
        "Importantly, Reality Scale's technology offers companies a powerful testbed for product development. Before even launching a product, businesses can use these immersive experiences to gather feedback, test features, and refine their offerings based on real user interactions. This capability provides an infinitely possible virtual environment to test products before their release, significantly reducing risks and improving market fit.",
      actionshortheading: "Key features and outcomes:",

      actionapplicationheading: "Applications in Other Sectors",

      actionapplicationlist: [
        {
          heading: "Banner Ads with Direct Experience Access",
          para: "Reality Scale transformed traditional banner ads into gateways for immersive experiences. For instance, a luxury watch brand created banner ads that instantly launched a 3D interactive model of their latest timepiece when clicked.",
        },
        {
          heading: "Virtual Company Museums",
          para: "A century-old manufacturing company created a virtual museum using Reality Scale's technology, telling its rich history through an interactive 3D environment. This chronological journey through company milestones integrated archival photos and videos within the 3D space.",
        },
        {
          heading: "Real Estate: Virtual Property Tours",
          para: "In the real estate sector, Reality Scale's technology has been used to create immersive virtual property tours, allowing potential buyers to explore homes from anywhere in the world. These tours include detailed 3D models of properties, customizable interiors, and virtual staging options.",
        },
      ],
      actionlist: [
        {
          list: "Interactive 3D Models: Users could view cars from every angle, open doors, and examine interiors.",
        },
        {
          list: "Real-time Customization: Customers could change colors, add accessories, and see updates instantly.",
        },
        {
          list: "Data Collection: The company gained insights into popular color choices, most-viewed features, and user engagement patterns.",
        },
        {
          list: "Increased Engagement: Average time spent on the website increased by 230%, with a 45% boost in lead generation.",
        },
      ],
      actioninnovativeheading: "Other Innovative Applications",
      actioninnovativelist: [
        {
          list: "FMCG Product Launches: Interactive 3D worlds and games for new product exploration.",
        },
        {
          list: "Educational Institutions: Virtual campus tours and sample lectures.",
        },
        {
          list: "Travel and Hospitality: Immersive hotel room and property previews.",
        },
      ],
    },
    {
      blogimage:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/blogImages/SELLING+THE+UNSEEN+BLOG/image3.png",
      url: "/blog/Selling_the_unseen",
      head: unique.head3,
      para: unique.para3,
      img: unique.img1,
    },
    {
      blogimage:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/blogImages/HUMANITIES+DILLEMMA+BLOG/image2.png",
      url: "/blog/unheard_graduates",
      head: unique.head4,
      para: unique.para4,
      img: unique.img1,
    },
  ],
  blogdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
  ],
  blogsingeldata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
  ],

  blogdetailsdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blogbanner,
    },
  ],

  blogdummydetailsdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
  ],

  featureImages: [
    {
      images: featureimage1,
    },
    {
      images: featureimage2,
    },
    {
      images: featureimage3,
    },
    {
      images: featureimage4,
    },
    {
      images: featureimage5,
    },
  ],

  data: [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Team",
      path: "/team",
    },
    {
      label: "Blog",
      path: "/blog",
    },
    {
      label: "Portfolio",
      path: "/portfolio",
    },
    {
      label: "Contact",
      path: "/contact",
    },
  ],

  menuItems: [
    // { text: "Projects", color: "#fff" },
    { href: "/transform/ai-ml-hub", text: "AI/ML HUb", color: "#fff" },
    {
      href: "/transform/automotive",
      text: "Automotive Solutions",
      color: "#fff",
    },
    { href: "/transform/web3-frontier", text: "Web3 Frontier", color: "#fff" },
    {
      href: "/transform/realestate-solution",
      text: "Real State Solution",
      color: "#fff",
    },
    { href: "/transform/xr-immersion", text: "XR Immersion", color: "#fff" },
    {
      href: "/transform/realityscale-framework",
      text: "Reality Scale Framework",
      color: "#fff",
    },
  ],

  links: [
    { url: "/privacy", text: "Privacy Policy" },
    { url: "/terms", text: "Terms and conditions" },
  ],

  addresses: [
    {
      country: "INDIA",
      address:
        "2nd Floor, D-320, Sector 63, Noida, Uttar Pradesh, 201307 INDIA",
    },
    {
      country: "USA",
      address: "30 N Gould St Ste R Sheridan, WY 82801 USA",
    },
    {
      country: "INDIA",
      address: "D-597, Sector-6, C.D.A, Cuttack, Odisha, Pin-753014",
    },
  ],

  socialIcons: [
    {
      href: "https://www.instagram.com/realityscale/",
      icon: <RiInstagramFill />,
    },
    {
      href: "https://www.youtube.com/channel/UCg0f7WD6d_-9N-wCN93cnPA",
      icon: <FaYoutube />,
    },
    {
      href: "https://x.com/realityscale",
      icon: <FaXTwitter />,
    },
    {
      href: "https://www.facebook.com/realityscale/",
      icon: <FaFacebook />,
    },
    {
      href: "https://www.linkedin.com/company/reality-scale/",
      icon: <FaLinkedin />,
    },
    {
      href: "https://www.behance.net/realityscale1",
      icon: <FaBehance />,
    },
    {
      href: "https://www.artstation.com/realityscale",
      icon: <FaArtstation />,
    },
  ],
  address:
    "2nd Floor, D-320, Sector 63 Rd, Sector 63, Noida, Uttar Pradesh, 201307 INDIA",
  address2: "30 N Gould St Ste R, Sheridan, WY 82801 USA",
  address3: "30 N Gould St Ste R, Sheridan, WY 82801 USA",
};
export default data;
