import React from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { COLORS } from "../../utils/defaultColor";
import { Link } from "react-router-dom";
import ArrowSVG from "../arrow";

const Herosection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        background: "#000",
        height: "120vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, md: 0 },
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="center"
          spacing={4}
          sx={{ position: "relative" }}
        >
          <Box
            sx={{
              position: { xs: "relative", md: "absolute" },
              left: { xs: 0, md: 0 },
              p: 2,
              zIndex: 2,
              textAlign: { xs: "left", md: "start" },
              top: { xs: 100, lg: 0 },
              height: { xs: "70vh" },
            }}
          >
            <Box>
              <Typography
                fontSize={{ xs: 32, md: 50 }}
                fontWeight="900 !important"
                color={COLORS.PRIMARY}
                letterSpacing={2}
              >
                Scaling{" "}
              </Typography>
              <Typography
                fontSize={{ xs: 32, md: 50 }}
                color={COLORS.WHITE}
                letterSpacing={2}
              >
                Imagination{" "}
              </Typography>
              <Typography
                fontSize={{ xs: 32, md: 50 }}
                fontWeight="900 !important"
                color={COLORS.PRIMARY}
                letterSpacing={2}
              >
                Engineering{" "}
              </Typography>
              <Typography
                fontSize={{ xs: 32, md: 50 }}
                color={COLORS.WHITE}
                letterSpacing={2}
              >
                Reality{" "}
              </Typography>
            </Box>
            <Box width={{ xs: "100%", md: 400 }} mt={{ xs: 2, md: 0 }}>
              <Typography
                fontSize={16}
                color={COLORS.GREY}
                mt={2}
                lineHeight={1.9}
              >
                Innovate seamlessly with Reality Scale. We architect digital
                ecosystems that scale up your vision, transforming customer
                engagement across industries.
              </Typography>
            </Box>
            <Stack
              direction={{ lg: "row", xs: "column" }}
              alignItems={{ lg: "center", xs: "start" }}
              spacing={2}
              mt={2}
            >
              <Link
                to="/contact"
                className="mil-link mil-muted mil-arrow-place contact_usbutton text-center"
              >
                <span> Contact Us</span>
                <ArrowSVG className="mil-arrow" fill="#000000" />
              </Link>
              <Link
                to="/demoreality"
                className="mil-button mil-arrow-place mil-btn-space mx-3 demo_button"
              >
                <span>Scale Your Reality</span>
                <ArrowSVG
                  className="mil-arrow demoarrow_button"
                  fill="#000000"
                />
              </Link>
            </Stack>
          </Box>
          <Box
            width={{ xs: "100%", md: 1200 }}
            height={{ xs: 300, md: 500 }}
            sx={{
              borderRadius: "50px",
              position: "relative",
              left: { xs: 0, md: 100 },
              overflow: "hidden",
            }}
          >
            {/* Video overlay */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                zIndex: 1,
                borderRadius: "50px",
              }}
            ></Box>
            {/* Video */}
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "#00000059",
                width: "100%",
                height: "100%",
                zIndex: 0,
                borderRadius: "50px",
              }}
            >
              <video
                style={{ width: "100%", height: "100%", borderRadius: "50px" }}
                autoPlay
                playsInline
                muted
                loop
              >
                <source src="https://reality-scale-buck.s3.amazonaws.com/Website+V02.mp4" />
              </video>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Herosection;
