import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
const SecondBlog = () => {
  let location = useLocation();

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    if (location.state) {
      setPageData(location.state.value);
    }
  }, [location.state]);

  return (
    <>
      <div className="">
        <Box className="mil-content">
          <div id="swupMain" className="mil-main-transition ">
            <div className="bg-black  page_alignment ">
              <div className="mil-inner-banner mainpage_alignment">
                <div className="mi-invert-fix">
                  <div className="mil-banner-content mil-up">
                    <div className="mil-animation-frame">
                      <div
                        className="mil-animation mil-position-1 mil-scale"
                        data-value-1="7"
                        data-value-2="1.6"
                        style={{
                          transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                        }}
                      >
                        <div className="mil-dodecahedron">
                          {[...Array(12)].map((_, index) => (
                            <div key={index} className="mil-pentagon">
                              {[...Array(5)].map((_, index) => (
                                <div key={index}></div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="container section_alignment">
                      <ul
                        className="mil-breadcrumbs mil-light "
                        style={{
                          padding: "0",
                          position: "relative",
                          zIndex: 999,
                        }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="#">
                            XR Revolution in Manufacturing and Beyond
                          </a>
                        </li>
                      </ul>
                      <h1 className="mil-muted" style={{ color: "#ffd94a" }}>
                        Exploring How{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          XR Turns{" "}
                        </span>
                        Factories into Lean
                        <span className="mil-thins" style={{ color: "white" }}>
                          {" "}
                          Saving Machines
                        </span>
                      </h1>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/yagyanseni-swain-5533201a0/details/skills/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography color={"#fff"} fontSize={12}>
                            Author: Yagyanseni Swain
                          </Typography>
                        </a>
                      </div>
                    </div>{" "}
                    <div className="container">
                      <Box>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            As the technological context keeps changing, XR
                            (Extended Reality) due to its unique features has
                            grown to become revolutionary in various industries.
                            Re Combining the elements of Virtual Reality (VR),
                            Augmented Reality (AR) and Mixed Reality (MR), it is
                            the function of XR to change ways people access the
                            virtual world and how they connect it with the
                            physical world. Although its features can stretch
                            gregariously, its influence is felt mostly in the
                            impacting the industries that are dependent on it.
                          </Typography>
                        </Box>
                      </Box>{" "}
                      <Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            The XR Revolution in Manufacturing
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Manufacturing, once the bastion of physical labor
                              and traditional processes, is undergoing a seismic
                              shift thanks to XR. From the factory floor to the
                              boardroom, XR is streamlining operations,
                              enhancing productivity, and opening new avenues
                              for innovation.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Design and Prototyping: From Concept to Reality
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Gone are the days of costly physical prototypes.
                              At Ford Motor Company, engineers now use VR to
                              design and test vehicles before a single piece of
                              metal is bent. This approach has reduced
                              development time by 30% and reduced the need for
                              physical prototypes by 50%. Designers can walk
                              around virtual cars, sit inside them, and make
                              real-time adjustments, all within a digital space.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Assembly Line Efficiency: Precision at Scale
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              XR is elevating assembly line precision to
                              unprecedented levels. At Boeing's factory in
                              Everett, Washington, technicians use AR headsets
                              to assemble complex wire harnesses for aircraft.
                              These headsets project 3D diagrams onto the
                              technician's field of view, guiding them through
                              each step of the intricate process. The result? A
                              25% reduction in production time and a remarkable
                              90% decrease in error rates.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Workforce Training: Skill Development Without Risk
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              XR is revolutionizing how workers acquire and hone
                              skills. Volkswagen has implemented VR training
                              programs across its global operations, allowing
                              employees to practice complex assembly tasks in a
                              risk-free virtual environment. This approach has
                              reduced training time by 50% while improving
                              knowledge retention by 75%.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Remote Collaboration: Expertise Without Borders
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              When machinery breaks down, every minute counts.
                              Thyssenkrupp, a global industrial engineering
                              company, leverages AR for remote maintenance.
                              Technicians on-site can connect with experts
                              worldwide, who can see exactly what the technician
                              sees and provide real-time guidance. This solution
                              has cut average repair times by 4 hours, saving
                              millions in downtime costs.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>{" "}
                      <Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            XR's Ripple Effect Across Industries
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              While manufacturing stands at the forefront of XR
                              adoption, other sectors are not far behind:
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Healthcare: Precision and Compassion
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Surgeons at Stanford Medicine are using VR to plan
                              complex procedures, reducing surgical times by up
                              to 20%. Meanwhile, AR apps are helping patients
                              visualize treatment options, improving
                              understanding and compliance.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Education: Learning Unbound
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Google Expeditions has taken over 10 million
                              students on virtual field trips, from the depths
                              of the ocean to the surface of Mars, broadening
                              horizons without leaving the classroom.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Retail: Try Before You Buy
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              IKEA's AR app allows customers to place virtual
                              furniture in their homes, reducing returns by 35%
                              and increasing customer satisfaction scores by
                              22%.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Architecture: Building the Future
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Foster + Partners, the renowned architectural
                              firm, uses VR to let clients walk through
                              buildings before construction begins, reducing
                              change orders by 40% and accelerating project
                              approvals.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Entertainment: Beyond the Screen
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              The NFL has partnered with AR company STRIVR to
                              provide fans with interactive game-day
                              experiences, boosting engagement and opening new
                              revenue streams.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Automotive: Driving Innovation
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Audi's VR showrooms allow customers to configure
                              and experience their dream car without a physical
                              model present, increasing sales conversion rates
                              by 60% in pilot locations.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Tourism: Journeys from Home
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              The Louvre's VR experience lets visitors explore
                              the museum's masterpieces from anywhere in the
                              world, attracting over 1 million virtual visitors
                              in its first year.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            Challenges and the Road Ahead
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              While XR shows great promise, it still faces some
                              significant challenges. The cost of hardware is
                              still high, although it is decreasing at a rapid
                              pace. Concerns about data privacy are prominent,
                              particularly as XR devices collect more personal
                              data. Moreover, integrating XR with existing
                              systems presents technical hurdles that require
                              careful consideration.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              However, the potential benefits greatly outweigh
                              these challenges. With the global expansion of 5G
                              networks, we will have the bandwidth and low
                              latency necessary for seamless XR experiences.
                              Advances in AI will make interactions in XR more
                              natural and intuitive. Integrating XR with other
                              emerging technologies like blockchain and IoT will
                              unlock new possibilities that we can hardly
                              imagine today.
                            </Typography>
                          </Box>
                        </Box>{" "}
                      </Box>{" "}
                      <Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            The Future is Extended
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              XR is transforming what's possible. In
                              manufacturing, it's merging the physical and
                              digital worlds, creating smart factories that are
                              more efficient, safer, and adaptable than ever.
                              Across various industries, XR is tearing down
                              barriers, driving innovation, and creating
                              experiences that once felt like science fiction.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              As we stand on the threshold of this extended
                              reality, one thing is clear: the future is for
                              those who are willing to embrace it. For
                              businesses looking to stay ahead, now is the time
                              to delve into XR's potential. The question is no
                              longer whether XR will reshape your industry, but
                              how quickly you'll adapt to the new reality it
                              offers.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              In this expansive world, the only limit is our
                              imagination. At Reality Scale, rather than just
                              observing this XR revolution we are contributing
                              to this change by actively engineering it. Our
                              team is crafting XR tools that empower
                              manufacturers to decode complex data streams,
                              refine intricate workflows, and cultivate employee
                              skills in ways previously unthinkable. We're not
                              content with the current bounds of reality; we're
                              forging new digital frontiers that redefine how we
                              interact with the physical world
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Welcome to the future – it's more authentic than
                              you realize, and with Reality Scale, it's
                              unfolding right before your eyes. Join us as we
                              construct the possibility in XR, reshaping
                              industries and recalibrating the very essence of
                              reality itself.
                            </Typography>
                          </Box>
                        </Box>{" "}
                      </Box>
                      <Box
                        sx={{
                          mt: 3,
                        }}
                      >
                        <Typography color={"#ffd94a"}>Source:</Typography>
                        <Box>
                          <div>
                            <a
                              style={{
                                color: "#fff",
                              }}
                              href="https://cadituk.com/extended-reality-xr-technology-for-manufacturing/#:~:text=Manufacturers%20are%20using%20XR%20technology,they%20are%20in%20the%20world"
                            >
                              <Typography variant="p">cadituk.com</Typography>
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://helplightning.com/blog/extended-reality-solutions/"
                              style={{
                                color: "#fff",
                              }}
                            >
                              <Typography variant="p">
                                helplightning.com
                              </Typography>
                            </a>
                          </div>
                          <div>
                            <a
                              style={{
                                color: "#fff",
                              }}
                              href="https://www.ey.com/en_gl/media/podcasts/decoding-innovation/2023/05/episode-9-how-extended-reality-technologies-can-transform-industries"
                            >
                              <Typography variant="p">ey.com</Typography>
                            </a>
                          </div>
                          <div>
                            <a
                              style={{
                                color: "#fff",
                              }}
                              href="https://www.linkedin.com/pulse/6-use-cases-xr-technology-manufacturing-sector-tesseract-imaging/"
                            >
                              <Typography variant="p">
                                xr-technology.com
                              </Typography>
                            </a>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default SecondBlog;
