import featureimage4 from "../../assests/images/Features/feature3.webp";
import featureimage3 from "../../assests/featurecostefficient.jpg";
import featureimage1 from "../../assests/images/Features/featureglobe.webp";
import featureimage5 from "../../assests/featurecontentcreation.jpg";
import featureimage2 from "../../assests/images/Features/featuredigitaltwin.webp";
import ai_integration from "../../assests/images/Features/feature_ai.webp";
import digital_twin from "../../assests/images/Features/feature_digitaltwin.webp";
import globe_reach from "../../assests/images/Features/feature_globe.webp";

let featureData = {
  dataType: [
    {
      id: 2,
      heading: "Digital  ",
      head: "Twins",
      image: "https://realityscalebucket.s3.eu-north-1.amazonaws.com/car.png",
      heading2: "See Your Vision in Stunning Detail",
      title: "Reality Modeling :",
      sub_title: "Photorealistic, interactive 3D product replicas.",
      title2: "Product Exploration :",
      sub_title2: "Comprehensive virtual product examination.",
      title3: "Boost Confidence and Sales :",
      sub_title3: "Informed decisions through virtual experiences.",
    },
    {
      id: 3,
      heading: "Intelligent  ",
      head: "Optimization",
      image:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/intelligence.png",
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Rapid Prototyping :",
      sub_title: "Faster market entry with digital prototypes.",
      title2: "Streamlined Operations :",
      sub_title2: "Optimized workflows and inventory management.",
      title3: "Enhanced Analytics :",
      sub_title3: "Deep insights for targeted marketing strategies.",
    },
    {
      id: 1,
      heading: "Global ",
      head: "Reach",
      image:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/GlobalReach.png",
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Omni Channel :",
      sub_title: "Seamless customer engagement across all devices",
      title2: "Be Anywhere  :",
      sub_title2: "Anytime: 24/7 global accessibility via cloud platform.",
      title3: "Scale and Performance :",
      sub_title3: "Effortless scaling with high-performance delivery.",
    },
    {
      id: 4,
      heading: "AI ",
      head: "Assistance",
      image: "https://realityscalebucket.s3.eu-north-1.amazonaws.com/ai.png",
      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "Intelligent Digital Twins :",
      sub_title: "AI-powered user interaction understanding.",
      title2: "AI Sales Assistant :",
      sub_title2: "24/7 personalized guidance and support.",
      title3: "Actionable Insights :",
      sub_title3: " Data-driven decision making from user behavior.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image:
        "https://realityscalebucket.s3.eu-north-1.amazonaws.com/ContentCreation.png",
      heading2: " Craft Compelling Experiences",
      title: "Tailored Content :",
      sub_title: "Customized materials for stronger engagement.",
      title2: "Rapid Asset Generation :",
      sub_title2: "Swift creation of adaptable marketing assets.",
      title3: "Customized Tools :",
      sub_title3: "Bespoke solutions for unique business needs.",
    },
  ],
  feature1: [
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature2: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature4: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature5: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
  ],
};

export default featureData;
