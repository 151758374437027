import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import image1 from "../../assests/images/aboutus.jpg";
import ArrowSVG from "../../components/arrow";
import "./index.css";
const FeatureDetails = () => {
  const location = useLocation();

  let Data = location && location.state && location.state.val;

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    if (location.state) {
      setPageData(location.state);
      // window.scrollTo(0, 0);
    }
  }, [location.state]);

  return (
    <div className="mil-content">
      <div id="swupMain" className="mil-main-transition">
        <div className="bg-black mil-dark-bg">
          <div className="mil-inner-banner">
            <div className="mi-invert-fix">
              <div className="mil-banner-content mil-up">
                <div className="mil-animation-frame">
                  <div
                    className="mil-animation mil-position-1 mil-scale"
                    data-value-1="7"
                    data-value-2="1.6"
                    style={{
                      transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                    }}
                  >
                    <div className="mil-dodecahedron">
                      {[...Array(12)].map((_, index) => (
                        <div key={index} className="mil-pentagon">
                          {[...Array(5)].map((_, index) => (
                            <div key={index}></div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="container">
                  <ul
                    className="mil-breadcrumbs mil-light mil-mb-60"
                    style={{ padding: "0" }}
                  >
                    <li>
                      <a href="/">Homepage</a>
                    </li>

                    <li>
                      <a href="#">Feature Detail's</a>
                    </li>
                  </ul>

                  {/* <h1
                    className="mil-muted mil-mb-60"
                    style={{ color: "#ffd94a" }}
                  >
                    Our{" "}
                    <span className="mil-thins" style={{ color: "white" }}>
                      Feature
                    </span>
                    <br /> Detail's{" "}
                    <span
                      className="mil-thins"
                      style={{ color: "white" }}
                    ></span>
                  </h1> */}

                  {/* <a className="mil-link mil-accent mil-arrow-place mil-down-arrow">
                    <span className="text-white">READ MORE</span>
                    <ArrowSVG className=" mil-arrow" fill="#000000" />
                  </a> */}
                </div>
                <div className="container">
                  <Typography variant="p" color={"#fff"}>
                    {pageData &&
                      pageData.val &&
                      pageData.val.realstateIntroduction}
                  </Typography>{" "}
                  <Box>
                    <div className="row mt-5 align-items-center">
                      <div className="col-sm-6">
                        <img src={image1} width={"100%"} height={"80%"} />
                      </div>
                      <div className="col-sm-6">
                        <Typography variant="h4">
                          {pageData &&
                            pageData.val &&
                            pageData.val.heading &&
                            pageData &&
                            pageData.val &&
                            pageData.val.realstatevisionheading}
                        </Typography>
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            {pageData && pageData.val && pageData.val.mainpara}{" "}
                            {pageData &&
                              pageData.val &&
                              pageData.val.realstatevisionpara}
                          </Typography>{" "}
                        </Box>
                        <Typography variant="p" marginTop={5} color={"#fff"}>
                          {pageData && pageData.val && pageData.val.mainpara2}
                        </Typography>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" marginTop={5} color={"#fff"}>
                            {pageData && pageData.val && pageData.val.mainpara3}
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "4rem",
                    }}
                  >
                    <div className="row mt-5 align-items-center">
                      <div className="col-sm-6">
                        <Typography variant="h4">
                          {pageData &&
                            pageData.val &&
                            pageData.val.automotiveplatform}
                        </Typography>
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            {pageData &&
                              pageData.val &&
                              pageData.val.subHeading}
                          </Typography>{" "}
                        </Box>
                        <Box
                          sx={{
                            my: 1,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            {pageData &&
                              pageData.val &&
                              pageData.val.capabilitiesHeading}
                          </Typography>{" "}
                        </Box>
                        <Box>
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.val &&
                              pageData.val.capabilities &&
                              pageData.val.capabilities.map((val, id) => {
                                return (
                                  <li>
                                    <Typography color={"#fff"}>
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>{" "}
                        <Box>
                          <Box>
                            <Typography variant="p" color={"#fff"}>
                              {pageData &&
                                pageData.val &&
                                pageData.val.CustomizableHeading}
                            </Typography>{" "}
                          </Box>
                          <Box>
                            <ul
                              style={{
                                color: "#fff",
                              }}
                            >
                              {pageData &&
                                pageData.val &&
                                pageData.val.Customizable &&
                                pageData.val.Customizable.map((val, id) => {
                                  return (
                                    <li>
                                      <Typography color={"#fff"}>
                                        {val.list}
                                      </Typography>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>
                        </Box>
                      </div>
                      <div className="col-sm-6">
                        <img src={image1} width={"100%"} height={"80%"} />
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "2rem",
                    }}
                  >
                    <div className="row mt-5 align-items-center">
                      <div className="col-sm-6">
                        <img src={image1} width={"100%"} height={"80%"} />
                      </div>
                      <div className="col-sm-6">
                        <Typography variant="h4">
                          {pageData &&
                            pageData.val &&
                            pageData.val.Technologiesheading}
                        </Typography>
                        <Box
                          sx={{
                            my: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            {pageData && pageData.val && pageData.val.techPara}
                          </Typography>{" "}
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              my: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              {pageData &&
                                pageData.val &&
                                pageData.val.virtualreality}
                            </Typography>{" "}
                          </Box>
                          <Box>
                            <ul
                              style={{
                                color: "#fff",
                              }}
                            >
                              {pageData &&
                                pageData.val &&
                                pageData.val.virtual &&
                                pageData.val.virtual.map((val, id) => {
                                  return (
                                    <li>
                                      <Typography color={"#fff"}>
                                        {val.list}
                                      </Typography>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>{" "}
                        </Box>{" "}
                        <Box>
                          <Box
                            sx={{
                              my: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              {pageData &&
                                pageData.val &&
                                pageData.val.mixedreality}
                            </Typography>{" "}
                          </Box>
                          <Box>
                            <ul
                              style={{
                                color: "#fff",
                              }}
                            >
                              {pageData &&
                                pageData.val &&
                                pageData.val.mixed &&
                                pageData.val.mixed.map((val, id) => {
                                  return (
                                    <li>
                                      <Typography color={"#fff"}>
                                        {val.list}
                                      </Typography>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>{" "}
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              my: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              {pageData &&
                                pageData.val &&
                                pageData.val.argumentreality}
                            </Typography>{" "}
                          </Box>
                          <Box>
                            <ul
                              style={{
                                color: "#fff",
                              }}
                            >
                              {pageData &&
                                pageData.val &&
                                pageData.val.argument &&
                                pageData.val.argument.map((val, id) => {
                                  return (
                                    <li>
                                      <Typography color={"#fff"}>
                                        {val.list}
                                      </Typography>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>{" "}
                        </Box>{" "}
                        <Box>
                          <Box
                            sx={{
                              my: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              {pageData &&
                                pageData.val &&
                                pageData.val.modelingreality}
                            </Typography>{" "}
                          </Box>
                          <Box>
                            <ul
                              style={{
                                color: "#fff",
                              }}
                            >
                              {pageData &&
                                pageData.val &&
                                pageData.val.model &&
                                pageData.val.model.map((val, id) => {
                                  return (
                                    <li>
                                      <Typography color={"#fff"}>
                                        {val.list}
                                      </Typography>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Box>{" "}
                        </Box>
                      </div>
                    </div>
                  </Box>
                  <Box>
                    <div className="row mt-5 align-items-center">
                      <div className="col-sm-6">
                        <Typography variant="h4">
                          {pageData && pageData.val && pageData.val.Advantages}
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "2rem",
                          }}
                        >
                          <ul
                            style={{
                              color: "#fff",
                            }}
                          >
                            {pageData &&
                              pageData.val &&
                              pageData.val.model &&
                              pageData.val.model.map((val, id) => {
                                return (
                                  <li>
                                    <Typography
                                      color={"#fff"}
                                      sx={{
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      {val.list}
                                    </Typography>
                                  </li>
                                );
                              })}
                          </ul>
                        </Box>{" "}
                      </div>
                      <div className="col-sm-6">
                        <img src={image1} width={"100%"} height={"80%"} />
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureDetails;
