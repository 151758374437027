import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
const UnseenBlog = () => {
  let location = useLocation();

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    if (location.state) {
      setPageData(location.state.value);
    }
  }, [location.state]);

  return (
    <>
      <div className="">
        <Box className="mil-content">
          <div id="swupMain" className="mil-main-transition ">
            <div className="bg-black  page_alignment ">
              <div className="mil-inner-banner mainpage_alignment">
                <div className="mi-invert-fix">
                  <div className="mil-banner-content mil-up">
                    <div className="mil-animation-frame">
                      <div
                        className="mil-animation mil-position-1 mil-scale"
                        data-value-1="7"
                        data-value-2="1.6"
                        style={{
                          transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                        }}
                      >
                        <div className="mil-dodecahedron">
                          {[...Array(12)].map((_, index) => (
                            <div key={index} className="mil-pentagon">
                              {[...Array(5)].map((_, index) => (
                                <div key={index}></div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="container section_alignment">
                      <ul
                        className="mil-breadcrumbs mil-light "
                        style={{
                          padding: "0",
                          position: "relative",
                          zIndex: 999,
                        }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="#">
                            Selling the unseen: My journey into digital property
                            replication
                          </a>
                        </li>
                      </ul>
                      <h1 className="mil-muted" style={{ color: "#ffd94a" }}>
                        Selling the unseen:{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          My journey{" "}
                        </span>
                        <br />
                        into digital property
                        <span className="mil-thins" style={{ color: "white" }}>
                          {" "}
                          replication
                        </span>
                      </h1>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/yagyanseni-swain-5533201a0/details/skills/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography color={"#fff"} fontSize={12}>
                            Author: Yagyanseni Swain
                          </Typography>
                        </a>
                      </div>
                    </div>{" "}
                    <div className="container">
                      <Box>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            Introduction
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mt: 1,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            As a business development manager in the digital
                            twin and virtual tour sector, I’ve had the chance to
                            witness the remarkable impact these technologies are
                            having across different industries. My journey has
                            been filled with engaging discussions, tough sales
                            pitches, and the rewarding sight of businesses
                            moving towards innovative customer engagement
                            strategies. In this blog post, I’ll share my
                            experiences and insights on how digital twin
                            technology is improving products and increasing
                            customer reach, especially in hospitality,
                            healthcare, educational institutions, and real
                            estate.
                          </Typography>
                        </Box>
                      </Box>{" "}
                      <Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            The Power of Digital Twins
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Digital twin technology provides a powerful new
                              way for businesses to visualize and present their
                              properties and facilities. Imagine being able to
                              walk through a hotel, explore a hospital, or tour
                              a university campus from the comfort of your own
                              home. That's the power of digital twins. They
                              offer an enhanced customer experience, improve
                              decision-making processes, and ultimately lead to
                              cost reductions and increased efficiency for
                              businesses.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              In my time selling these solutions, I've seen
                              firsthand how they've transformed the way
                              industries operate. Hotels can now offer potential
                              guests a virtual walkthrough of their rooms and
                              facilities before booking. Hospitals use digital
                              twins to help patients navigate complex buildings
                              and plan equipment placement. Universities attract
                              students from around the globe with immersive
                              campus tours. And real estate agents? They're
                              closing deals faster than ever by offering
                              detailed property tours to buyers who might be
                              thousands of miles away.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            The Sales Process: Making Virtual Tours a Reality
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Now, you might be wondering, "How does a business
                              get started with digital twins?" Well, that's
                              where I come in. My job is to connect with
                              potential clients, often through meetings and
                              explain just how accessible and valuable a virtual
                              tour can be.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              The biggest selling point? The simplicity of the
                              process. When I talk to clients, I emphasize that
                              creating a digital twin isn't as complex as they
                              might think. Our team visits the client's
                              location, armed with cameras and drones, to
                              capture every nook and cranny of the property. We
                              take detailed pictures and stunning aerial shots,
                              ensuring we don't miss a thing. The pricing is
                              straightforward too, usually based on the area of
                              the property we're capturing.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              Once we've done the on-site work, our technical
                              team takes over. They're the wizards who transform
                              those images into an immersive, interactive
                              digital twin. And the best part? It doesn't take
                              long. Before the client knows it, they have a
                              fully functional virtual tour ready to wow their
                              customers.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Keeping It Fresh: The Importance of Maintenance
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              One aspect that I always make sure to discuss with
                              clients is the importance of keeping their digital
                              twin up to date. Properties change over time –
                              renovations happen, new facilities are added, or
                              layouts are altered. When these changes occur,
                              it's crucial to update the virtual tour to match.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              I remember a client in the hospitality industry
                              who had just completed a major renovation of their
                              hotel lobby and restaurant. They were thrilled
                              with the new look but realized their existing
                              virtual tour was now outdated. We worked with them
                              to update their digital twin, capturing the new
                              spaces and integrating them seamlessly into the
                              existing tour. The result? A fresh, accurate
                              representation of their property that continued to
                              impress potential guests.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              This maintenance aspect is actually a great
                              opportunity for ongoing relationships with
                              clients. It allows us to stay connected, ensuring
                              their digital twin always reflects the current
                              reality of their property. It's not just about
                              creating a one-time product; it's about providing
                              a living, evolving tool that grows with the
                              client's business.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            The Human Touch in a Digital World
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              While digital twins offer incredible technological
                              advantages, I've found that the human element
                              remains crucial. In my conversations with clients,
                              I often emphasize that these virtual tours aren't
                              meant to replace human interaction entirely.
                              Instead, they enhance it.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              For example, in the healthcare sector, digital
                              twins help patients familiarize themselves with a
                              hospital layout before a visit, reducing anxiety
                              and improving the overall experience. But they
                              don't replace the caring touch of healthcare
                              professionals. In real estate, virtual tours help
                              buyers narrow down their options, but agents still
                              play a vital role in guiding clients through the
                              purchasing process.
                            </Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={23}
                            fontWeight={300}
                          >
                            Looking to the Future
                          </Typography>
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              As of now I have expanded my career option to
                              marketing, and operations, but I will always
                              remain interested and excited about the future of
                              digital twin technology. I see a world where these
                              virtual experiences become even more interactive
                              and accessible. Imagine being able to modify room
                              layouts in real-time during a virtual tour, or
                              accessing detailed information about equipment
                              just by clicking on it in the digital twin.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              In this changing environment, Reality Scale
                              emerges as a leader in transforming digital
                              interactions. Their advanced digital twin
                              technology surpasses basic visual representation,
                              providing an extraordinary level of detail and
                              interactivity. What distinguishes Reality Scale is
                              their dedication to crafting genuinely immersive
                              experiences that merge the physical and digital
                              worlds.
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              With Reality Scale's offerings, users can engage
                              with a space in ways that were once unimaginable.
                              Picture being able to accurately measure
                              dimensions within a virtual setting or retrieving
                              real-time information about specific objects or
                              areas just by clicking on them. This kind of
                              functionality elevates digital twins from simple
                              visual tools to essential resources for
                              decision-making and engagement
                            </Typography>
                          </Box>{" "}
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Typography variant="p" color={"#fff"}>
                              So, the next time you're planning a trip,
                              considering a new home, or even preparing for a
                              hospital visit, don't be surprised if you're
                              offered a virtual tour. Remember, behind that
                              immersive experience is a team of dedicated
                              professionals working to bring the physical world
                              into the digital realm, one property at a time.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default UnseenBlog;
