import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import data from "../../assests/data/data";
import ArrowSVG from "../../components/arrow";
import "./index.css";
import { useNavigate } from "react-router-dom";

const RealityScaleBlog = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRoute = (val) => {
    navigate(`${val.url}`, {
      state: { val },
    });
  };

  const homePage = () => {
    navigate("/");
  };

  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition" data-swup="0">
          <div className="mil-inner-banner">
            <div
              className="mil-banner-content mil-up"
              style={{
                rotate: "none",
                scale: "none",
                transform: "translate(0px, 0px)",
                opacity: 1,
                translate: "none",
              }}
            >
              {/* <div className="mil-animation-frame">
                <div
                  className="mil-animation mil-position-4 mil-dark mil-scale"
                  data-value-1="6"
                  data-value-2="1.4"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                    WebkitTransform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                  }}
                >
                  <div className="mil-dodecahedron">
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className="mil-pentagon">
                        {[...Array(5)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
              <div
                className="container"
                style={{
                  padding: "0",
                  position: "relative",
                  zIndex: 999,
                }}
              >
                <ul
                  className="mil-breadcrumbs"
                  style={{
                    padding: "0",
                    position: "relative",
                    zIndex: 999,
                    // marginTop: "5rem",
                  }}
                >
                  <li>
                    <a href="/">Homepage</a>
                  </li>
                  <li>
                    <a href="/blog">blog</a>
                  </li>
                </ul>
                <h1 className="" style={{ padding: "0" }}>
                  Exploring <span className="mil-thin">the world</span>
                  <br /> Through our <span className="mil-thin">Blog</span>
                </h1>
                <a
                  className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("target-element")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <span className="text-dark">Publication</span>
                  <ArrowSVG className="mil-arrow" fill="#000000" />
                </a>
              </div>
            </div>
          </div>

          <section
            className="mil-soft-bg"
            style={{
              marginTop: "10px",
            }}
            id="target-element"
          >
            <div className="container py-4">
              {" "}
              <div className="row mil-services-grid">
                {data.uniquedata.map((val, index) => (
                  <div
                    key={index}
                    className="col-sm-6 col-md-4 col-lg-4 section_bottom mb-5"
                  >
                    <div onClick={() => handleRoute(val)}>
                      <a href={val.url}>
                        <img
                          src={val.blogimage}
                          style={{
                            width: "100%",
                            height: "250px",
                            maxHeight: "450px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </a>
                    </div>
                    <Box
                      sx={{
                        mt: 2,
                        height: "22vh",
                      }}
                    >
                      <Typography
                        variant="h1"
                        color={"#000"}
                        sx={{
                          fontSize: "30px",
                          fontWeight: 600,
                        }}
                      >
                        {val.head}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                          color: "grey",
                          fontSize: "16px",
                          fontWeight: 300,
                        }}
                        variant="h6"
                      >
                        {val.para}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 5,
                      }}
                    >
                      <div onClick={() => handleRoute(val)}>
                        <a href={val.url}>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#000",
                            }}
                          >
                            Read more <FaArrowRightLong className="ms-2" />
                          </span>
                        </a>
                      </div>
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default RealityScaleBlog;
