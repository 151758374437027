import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../style/transform.css";
import automotiveimage from "../../assests/images/aboutus.png";
const Framework = () => {
  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black mil-dark-bg page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="">
                      <ul
                        className="mil-breadcrumbs mil-light "
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">Reality Scale Framework</a>
                        </li>
                      </ul>
                      <h1 className="mil-muted " style={{ color: "#ffd94a" }}>
                        Reality Scale{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          Framework{" "}
                        </span>
                        {/* <br /> is finally{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        launched
                      </span> */}
                      </h1>
                    </div>
                    <div>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="p" color={"#fff"}>
                          The Reality Scale Framework is a comprehensive suite
                          of advanced tools designed to revolutionize the
                          development and deployment of cloud-native 3D
                          products.
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Originally crafted for in-house use at Reality
                            Scale, this powerful framework has been expanded and
                            refined to benefit businesses across various
                            industries, including architecture, civil
                            engineering, fashion, and real estate.
                          </Typography>
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            marginTop: 4,
                          }}
                        >
                          Core Features
                        </Typography>
                      </Box>
                      {/* automotive defination start */}
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "350px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Reality Scale Asset System
                              </Typography>
                              <Box
                                sx={{
                                  mt: 2,
                                }}
                              >
                                <ul
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  <li>
                                    <b>Custom Asset and Material Library:</b>{" "}
                                    Access a vast collection of pre-built assets
                                    and materials
                                  </li>{" "}
                                  <li>
                                    <b>Create Your Own Library:</b> Develop and
                                    manage custom asset libraries tailored to
                                    your needs
                                  </li>{" "}
                                  <li>
                                    <b>Universal Accessibility:</b> Access
                                    assets seamlessly in any software and our
                                    cloud platforms.
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive defination ends */}{" "}
                      {/* automotive NeoReal Platform start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Global Scaling Architecture
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Auto-scaling Solution:</b> Dynamically
                                  adapts to increasing demands, ensuring optimal
                                  performance across various scenarios.
                                </li>
                                <li className="mt-2">
                                  <b>Load Balancing:</b> Intelligent
                                  distribution of resources to maintain
                                  consistent performance during usage spikes.
                                </li>
                                <li className="mt-2">
                                  <b>Geographic Optimization:</b> Deploy content
                                  closer to end-users for reduced latency and
                                  improved user experience.
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive NeoReal Platform ends */}{" "}
                      {/* automotive Immersive technology start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Logging and Debugging System
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ul
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Centralized Logs:</b> Comprehensive logging
                                  across the entire architecture for easy
                                  troubleshooting and performance monitoring.
                                </li>
                                <li className="mt-2">
                                  <b>Real-time Monitoring:</b> Live tracking of
                                  system health and performance metrics.
                                </li>{" "}
                                <li className="mt-2">
                                  <b>Advanced Debugging Tools:</b> Sophisticated
                                  tools for rapid issue identification and
                                  resolution.
                                </li>
                              </ul>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive Immersive Technology ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Cross-Platform Compatibility
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Multi-device Support:</b> Seamless
                                  functionality across desktops, tablets, and
                                  mobile devices.
                                </li>
                                <li className="mt-2">
                                  <b>Consistent Experience:</b> Maintain visual
                                  fidelity and functionality across different
                                  platforms.
                                </li>
                                <li className="mt-2">
                                  <b>Adaptive UI:</b> Automatically adjust user
                                  interfaces for optimal viewing on various
                                  screen sizes.
                                </li>
                              </ol>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}{" "}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Data-driven and Modular Design{" "}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Flexible Content Management:</b> Easily
                                  update and manage content without deep
                                  technical knowledge.
                                </li>
                                <li className="mt-2">
                                  <b>Modular Components:</b> Reusable modules
                                  for rapid development and consistent design.
                                </li>
                                <li className="mt-2">
                                  <b>API-First Approach:</b> Robust APIs for
                                  easy integration with existing systems and
                                  third-party tools.
                                </li>
                              </ol>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                Content Creation Tools
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Real-time Rendering:</b> High-fidelity
                                  visualization tools for instant content
                                  creation and editing.
                                </li>
                                <li className="mt-2">
                                  <b>Collaborative Editing:</b> Multi-user
                                  editing capabilities for team-based projects.
                                </li>
                                <li className="mt-2">
                                  <b>Version Control:</b> Built-in versioning
                                  system for efficient content management and
                                  rollback capabilities.
                                </li>
                              </ol>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}{" "}
                      {/* automotive advantage start */}
                      <Box
                        sx={{
                          mt: 6,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12} md={8} lg={6}>
                            <img
                              src={automotiveimage}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "350px",
                                objectFit: "cover",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                AI/ML Integration
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Intelligent Asset Recommendations: </b>{" "}
                                  AI-powered suggestions for relevant assets and
                                  materials
                                </li>
                                <li className="mt-2">
                                  <b>Automated Optimization:</b> Machine
                                  learning algorithms for performance and
                                  resource optimization
                                </li>
                                <li className="mt-2">
                                  <b>Predictive Analytics:</b> AI-driven
                                  insights for user behavior and content
                                  effectiveness
                                </li>
                              </ol>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* automotive advantage ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Framework;
