import { Typography } from "@mui/material";
import Aos from "aos";
import React, { useEffect } from "react";
import image1 from "../../assests/images/Welcome to Reality Scale/digital_Twin.webp";
import "aos/dist/aos.css";
import "./index.css";

const DigitalTwin = () => {
  useEffect(() => {
    Aos.init({
      once: false,
    });
  }, []);

  return (
    <div>
      <div className="screen_display">
        <div className="container container_alignment">
          <div
            className="row align-items-center"
            data-aos="fade-left"
            data-aos-offset="20"
            data-aos-delay="50"
            data-aos-duration="800"
            data-aos-easing="ease-in-out"
            data-aos-anchor-placement="top"
          >
            <div className="col-sm-6">
              <div>
                <img
                  src={image1}
                  height={"100%"}
                  width={"100%"}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                />
              </div>
            </div>

            <div
              className="col-sm-6 p-0 m-0"
              style={{ display: "flex", alignItems: "center" }}
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="text_coloum">
                <h1 className="mb-0 main_heading">What is a</h1>
                <h1 className="sub_heading">Digital Twin?</h1>
                <p className="mt-3 section_text mb-2">
                  A digital twin is a technology concept of a virtual
                  environment, in simpler terms, it is a digital replica or
                  model of an object in the physical world. This twin, or
                  digital counterpart, runs across all interactions and
                  processes of the said object, using real-time data from the
                  physical world to update itself. It optimizes based on
                  simulations, supplemented by machine learning, to help make
                  decisions, monitor, and predict the performance of physical
                  assets in real-time.
                </p>
                <Typography
                  variant="h1"
                  color={"#000"}
                  fontSize={20}
                  fontWeight={600}
                  className="sub_text"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="150"
                >
                  How does a digital twin work?
                </Typography>
                <p
                  className="section_text mb-0 mt-2"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  Let’s understand Data Assimilation. It is the process of
                  integrating newly received data and observations from a
                  subject with a forecasted model. Subsequently, the forecasted
                  model is updated with the received data. This continual flow
                  of obtained data, periodically updating the model is called
                  Data assimilation. This approach is fundamental to how digital
                  twins operate. The exchange of data takes place in real-time
                  via IOT (Internet of Things), connecting the model (digital
                  twin) to the physical asset.
                </p>
                <p
                  className="section_text mb-0"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                >
                  Meaning- your digital twin is not static but a dynamic and
                  evolving entity, personalized to the parameters of the
                  physical asset.
                </p>
                <p
                  className="section_text"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                >
                  The rendition of the digital twin varies and depends on the
                  intended use. It can be used to optimize operations across all
                  domains; to predict outcomes with “what if” simulations,
                  operational management, fault classification etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobilescreen_alignment">
        <div className="row">
          <div className="col-sm-12">
            <img
              src={image1}
              height={"100%"}
              width={"100%"}
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-sm-12">
            <div className="text_coloum mt-4 px-2">
              <h1 className="mb-0 main_heading">What is a</h1>
              <h1 className="sub_heading">Digital Twin?</h1>
              <p className="mt-3 section_text mb-2">
                A digital twin is a technology concept of a virtual environment,
                in simpler terms, it is a digital replica or model of an object
                in the physical world. This twin, or digital counterpart, runs
                across all interactions and processes of the said object, using
                real-time data from the physical world to update itself. It
                optimizes based on simulations, supplemented by machine
                learning, to help make decisions, monitor, and predict the
                performance of physical assets in real-time.
              </p>
              <Typography
                variant="h1"
                color={"#000"}
                fontSize={20}
                fontWeight={600}
                className="sub_text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="150"
              >
                How does a digital twin work?
              </Typography>
              <p
                className="section_text mb-0 mt-2"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                Let’s understand Data Assimilation. It is the process of
                integrating newly received data and observations from a subject
                with a forecasted model. Subsequently, the forecasted model is
                updated with the received data. This continual flow of obtained
                data, periodically updating the model is called Data
                assimilation. This approach is fundamental to how digital twins
                operate. The exchange of data takes place in real-time via IOT
                (Internet of Things), connecting the model (digital twin) to the
                physical asset.
              </p>
              <p
                className="section_text mb-0"
                data-aos="fade-up"
                data-aos-delay="250"
                data-aos-duration="1000"
              >
                Meaning- your digital twin is not static but a dynamic and
                evolving entity, personalized to the parameters of the physical
                asset.
              </p>
              <p
                className="section_text"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                The rendition of the digital twin varies and depends on the
                intended use. It can be used to optimize operations across all
                domains; to predict outcomes with “what if” simulations,
                operational management, fault classification etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalTwin;
