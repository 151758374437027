import React, { useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import "./index.css";
import ArrowSVG from "../../components/arrow";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

export const isEmail = (value) => {
  const checkvalue = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const hasMultipleDots = /\.{2,}/;
  return checkvalue.test(value) && !hasMultipleDots.test(value);
};

const Contactus = () => {
  const contact = [
    {
      icon: <BsFillTelephoneFill size={30} color="#ffd94a" className="icon" />,
      name: "Contact Phone Number",
      value: "+91 8800291352",
    },
    {
      icon: <AiFillMail size={30} color="#ffd94a" className="icon" />,
      name: "Our Email Address",
      value: "info@realityscale.com",
    },
    {
      icon: <HiLocationMarker size={30} color="#ffd94a" className="icon" />,
      name: "Our Location",
      value:
        "2nd Floor, D-320, Sector 63 Rd, Sector 63, Noida, Uttar Pradesh 201307",
    },
  ];

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const inputHandler = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (id === "email") {
      if (!isEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prevErrors) => {
          const { email, ...remainingErrors } = prevErrors;
          return remainingErrors;
        });
      }
    }
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!state.firstname) newErrors.firstname = "First name is required";
    if (!state.lastname) newErrors.lastname = "Last name is required";
    if (!state.email) {
      newErrors.email = "Email is required";
    } else if (!isEmail(state.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!state.mobile) {
      newErrors.mobile = "Phone number is required";
    } else if (!validatePhone(state.mobile)) {
      newErrors.mobile = "Phone number must be 10 digits";
    }
    if (!state.message) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    const formData = {
      firstname: state.firstname,
      lastname: state.lastname,
      email: state.email,
      mobile: state.mobile,
      message: state.message,
    };

    emailjs
      .send(
        "service_8zemqph",
        "template_7g3m51k",
        formData,
        "THTLmasKFdpP4EebI"
      )
      .then(
        (result) => {
          setLoading(false);
          toast.success("Message sent successfully!");
          setState({
            firstname: "",
            lastname: "",
            email: "",
            mobile: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          toast.error("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <>
      <div className="bg-black mil-dark-bg mt-top">
        <div className="mil-inner-banner">
          <div
            className="container wow fadeInUp animated"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeInUp",
            }}
          >
            <div className="section-title">
              <h5 className="section-title__tagline section-title__tagline--has-dots text-grey text-center">
                Let's Talk
              </h5>
              <h2 className="section-title__title text-white text-center">
                Feel free to get in touch
                <br /> with Reality
              </h2>
            </div>
            <div className="contact-one__left text-center">
              <div className="contact-one__form-box">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          onChange={inputHandler}
                          id="firstname"
                          value={state.firstname}
                        />
                        {errors.firstname && (
                          <p className="error">{errors.firstname}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          onChange={inputHandler}
                          id="lastname"
                          value={state.lastname}
                        />
                        {errors.lastname && (
                          <p className="error">{errors.lastname}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          onChange={inputHandler}
                          id="mobile"
                          value={state.mobile}
                        />
                        {errors.mobile && (
                          <p className="error">{errors.mobile}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-one__input-box">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          onChange={inputHandler}
                          id="email"
                          value={state.email}
                        />
                        {errors.email && (
                          <p className="error text-align-start">
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="contact-one__input-box">
                        <textarea
                          placeholder="Your Message"
                          name="message"
                          onChange={inputHandler}
                          id="message"
                          value={state.message}
                        ></textarea>
                        {errors.message && (
                          <p className="error">{errors.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-4">
                      {loading ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#ffd94a",
                              width: "20%",
                              padding: 2,
                              borderRadius: "20px",
                              cursor: "pointer",
                            }}
                          >
                            ...Loading
                          </Box>
                        </Box>
                      ) : (
                        <button
                          type="submit"
                          className="mil-services-button mil-button mil-arrow-place"
                          onClick={submitHandler}
                        >
                          <span>Send Message</span>
                          <ArrowSVG className="mil-arrow" fill="#000000" />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
