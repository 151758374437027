import { Box, Typography } from "@mui/material";
import React from "react";

const Launch = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FFD94A",
        backdropFilter: "blur(5px)",
        textAlign: "center",
        color: "#000",
        position: "fixed",
        zIndex: 999,
        top: { lg: 0, xs: 85 },
        width: "100%",
        p: 1,
      }}
    >
      <Typography fontSize={{ lg: 14, xs: 12 }} fontWeight={600}>
        Evolution in Progress: Our new website is under development, for
        improved experience and deeper insights into our innovative solutions.
        Thank you for your patience during this transition.
      </Typography>
    </Box>
  );
};

export default Launch;
