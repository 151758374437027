import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import immersionimg from "../../assests/transform/automotive/immersion.png";
import immersion14 from "../../assests/transform/automotive/immersion14.png";
import "../../style/transform.css";
const Immersion = () => {
  const [value, setValue] = useState("one");

  const tabsData = [
    {
      img: immersion14,
      value: "one",
      label: "Healthcare",
      challangeheading: "Challenges:",
      chanllangelist: [
        {
          list: "High-stakes environments requiring ultra-precise simulations",
        },
        {
          list: "Strict regulatory compliance and data privacy concerns",
        },
        {
          list: "Integration with existing medical systems and equipment",
        },
      ],
      realityscaleheading: "Reality Scale Solutions:",
      realityscalelist: [
        {
          list: "Medical-grade accuracy in XR simulations, validated by leading professionals",
        },
        {
          list: "HIPAA-compliant data handling and storage",
        },
        {
          list: "Seamless integration with major EHR systems and medical imaging technologies",
        },
      ],
      benefitsheading: "Benefits:",
      benefitslist: [
        {
          list: "Enhanced surgical precision through immersive pre-operative planning",
        },
        {
          list: "Improved patient outcomes via realistic medical training simulations",
        },
        {
          list: "Reduced medical errors and increased patient safety",
        },
      ],
    },
    {
      img: immersion14,
      value: "two",
      label: "Manufacturing",
      challangeheading: "Challenges:",
      chanllangelist: [
        {
          list: "Complex assembly processes requiring real-time guidance",
        },
        {
          list: "High-cost consequences for production errors",
        },
        {
          list: "Diverse workforce with varying levels of technical expertise",
        },
      ],
      realityscaleheading: "Reality Scale Solutions:",
      realityscalelist: [
        {
          list: "AR-guided assembly instructions overlaid directly onto work areas",
        },
        {
          list: "Real-time quality control integration with IoT sensors",
        },
        {
          list: "Intuitive, language-independent visual guides",
        },
      ],
      benefitsheading: "Benefits:",
      benefitslist: [
        {
          list: "Significant reduction in assembly errors and associated costs",
        },
        {
          list: "Increased production efficiency and reduced training time",
        },
        {
          list: "Improved worker safety through AR-assisted hazard identification",
        },
      ],
    },
    {
      img: immersion14,
      value: "three",
      label: "Education",
      challangeheading: "Challenges:",
      chanllangelist: [
        {
          list: "Engaging students in an increasingly digital world",
        },
        {
          list: "Providing equitable access to quality educational experiences",
        },
        {
          list: "Visualizing complex or abstract concepts",
        },
      ],
      realityscaleheading: "Reality Scale Solutions:",
      realityscalelist: [
        {
          list: "Immersive, interactive learning environments",
        },
        {
          list: "Cloud-based delivery ensuring accessibility from various devices",
        },
        {
          list: "3D visualization tools for abstract concepts in sciences and mathematics",
        },
      ],
      benefitsheading: "Benefits:",
      benefitslist: [
        {
          list: "Increased student engagement and improved learning outcomes",
        },
        {
          list: "Democratized access to high-quality educational experiences",
        },
        {
          list: "Enhanced understanding of complex subjects through visual, hands-on learning",
        },
      ],
    },
    {
      img: immersion14,
      value: "four",
      label: "Retail",
      challangeheading: "Challenges:",
      chanllangelist: [
        {
          list: "High return rates due to mismatched customer expectations",
        },
        {
          list: "Limited physical space for product showcases",
        },
        {
          list: "Creating engaging shopping experiences in e-commerce",
        },
      ],
      realityscaleheading: "Reality Scale Solutions:",
      realityscalelist: [
        {
          list: "Hyper-realistic AR try-on experiences for clothing, makeup, and home decor",
        },
        {
          list: "Virtual showrooms allowing endless product displays in limited physical space",
        },
        {
          list: "Interactive 3D product visualizations for e-commerce platforms",
        },
      ],
      benefitsheading: "Benefits:",
      benefitslist: [
        {
          list: "Reduced return rates and increased customer satisfaction",
        },
        {
          list: "Optimized use of retail space and expanded product offerings",
        },
        {
          list: "Enhanced online shopping experiences leading to higher conversion rates",
        },
      ],
    },
    {
      img: immersion14,
      value: "five",
      label: "Architecture & Real Estate",
      challangeheading: "Challenges:",
      chanllangelist: [
        {
          list: "Communicating complex designs to non-technical stakeholders",
        },
        {
          list: "Costly and time-consuming physical model creation",
        },
        {
          list: "Difficulty in visualizing spaces before they're built",
        },
      ],
      realityscaleheading: "Reality Scale Solutions:",
      realityscalelist: [
        {
          list: "Immersive VR walkthroughs of architectural designs",
        },
        {
          list: "Real-time collaborative design tools in mixed reality",
        },
        {
          list: "AR visualization of properties and renovations in existing spaces",
        },
      ],
      benefitsheading: "Benefits:",
      benefitslist: [
        {
          list: "Improved client communication and faster approval processes",
        },
        {
          list: "Significant cost and time savings in the design phase",
        },
        {
          list: "Enhanced property marketing and increased sales",
        },
      ],
    },
    {
      img: immersion14,
      value: "six",
      label: "The Reality Scale Transformation",
      benefitsheading: "Challenges:",
      benefitslist: [
        {
          list: "Intuitive: Minimal training required, accessible to team members at all technical levels",
        },
        {
          list: "Flexible: Modular approach for customization to meet unique industry needs",
        },
        {
          list: "Scalable: Solutions that grow with your business, from startups to global enterprises",
        },
        {
          list: "AI-Enhanced: Advanced AI features like object recognition and predictive analytics",
        },
        {
          list: "Innovative: Enhanced cross-platform and cloud experiences leveraging our ecosystem",
        },
        {
          list: "Future-Proof: Continuous innovation ensuring your XR solutions evolve with technology",
        },
      ],
    },
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSlideChange = (index) => {
    const selectedTab = tabsData[index];
    setValue(selectedTab.value);
  };

  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          <div className="bg-black page_alignment ">
            <div className=" mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="container">
                      <ul
                        className="mil-breadcrumbs mil-light "
                        style={{ padding: "0" }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="#">xr immersion</a>
                        </li>
                      </ul>
                      <h1 className="mil-muted " style={{ color: "#ffd94a" }}>
                        XR IMMERSIVE{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          SOLUTIONS{" "}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className="container mt-5">
                    <div>
                      {/* vision section start */}
                      <Box>
                        <Typography variant="p" color={"#fff"}>
                          At Reality Scale, we're revolutionising how businesses
                          and individuals interact with digital content. Our XR
                          Immersive Solutions seamlessly blend the physical and
                          digital worlds, creating intuitive experiences that
                          expand human potential and solve complex business
                          challenges.
                        </Typography>
                        <Box
                          sx={{
                            mt: 5,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={12} md={4} lg={6}>
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "start",
                                }}
                              >
                                <Typography
                                  variant="h2"
                                  fontWeight={500}
                                  fontSize={35}
                                >
                                  Understanding XR: The Future of Digital
                                  Interaction
                                </Typography>
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography color={"#fff"} variant="p">
                                    Our XR solutions adapt to make the most
                                    impactful experience for each unique
                                    scenario, dissolving barriers between the
                                    digital and physical realms.
                                  </Typography>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography variant="h6">
                                    Key Components:
                                  </Typography>
                                  <ul
                                    style={{
                                      color: "#fff",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <li>
                                      <b>Augmented Reality (AR): </b> Enhances
                                      the real world with digital overlays
                                    </li>
                                    <li className="mt-2">
                                      <b>Virtual Reality (VR): </b> Immerses
                                      users in fully digital environments
                                    </li>
                                    <li className="mt-2">
                                      <b>Mixed Reality (MR): </b> Blends digital
                                      objects with the real world for
                                      simultaneous interaction
                                    </li>
                                  </ul>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    mt: 3,
                                  }}
                                >
                                  <Typography variant="h6">
                                    The Reality Scale Advantage
                                  </Typography>
                                  <ul
                                    style={{
                                      color: "#fff",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <li>
                                      <b>Adaptive Experiences: </b> Our XR
                                      solutions morph to meet specific needs
                                      across industries
                                    </li>
                                    <li className="mt-2">
                                      <b>Seamless Transitions: </b> Move fluidly
                                      between augmented, virtual, and mixed
                                      realities
                                    </li>
                                    <li className="mt-2">
                                      <b>Comprehensive Problem-Solving: </b>{" "}
                                      Tackle complex challenges with a full
                                      spectrum of reality-altering tools
                                    </li>
                                  </ul>
                                </Box>{" "}
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                              <img
                                src={immersionimg}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "700px",
                                  objectFit: "cover",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                      {/* content section start */}
                      <Box
                        sx={{
                          my: 7,
                        }}
                      >
                        <Typography variant="h2" fontWeight={500} fontSize={35}>
                          Industry-Specific Solutions
                        </Typography>
                        <Box sx={{ width: "100%", marginTop: "2rem" }}>
                          {/* Tabs Section */}
                          <Tabs
                            value={value}
                            onChange={handleTabChange}
                            TabIndicatorProps={{ style: { display: "none" } }}
                          >
                            {tabsData.map((tab) => (
                              <Tab
                                key={tab.value}
                                value={tab.value}
                                label={tab.label}
                                wrapped
                                sx={{
                                  color:
                                    value === tab.value
                                      ? "#ffd94a !important"
                                      : "#fff",
                                  borderBottom:
                                    value === tab.value
                                      ? "2px solid #ffd94a"
                                      : "2px solid transparent",
                                }}
                              />
                            ))}
                          </Tabs>

                          <Carousel
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            selectedItem={tabsData.findIndex(
                              (tab) => tab.value === value
                            )}
                            onChange={handleSlideChange}
                          >
                            {tabsData.map((tab) => (
                              <Box
                                key={tab.value}
                                sx={{
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                {/* <img src={tab.img} height={"180px"} /> */}

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexDirection: "row",
                                    padding: "10px 22px 0 35px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Typography
                                      variant="h1"
                                      fontSize={30}
                                      fontWeight={600}
                                      sx={{ textAlign: "left" }}
                                    >
                                      {tab.label}
                                    </Typography>

                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab && tab.benefitsheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.benefitslist?.map((val, id) => (
                                            <li key={id}>
                                              <Typography
                                                sx={{
                                                  textAlign: "left",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                {val.list}
                                              </Typography>
                                            </li>
                                          ))}
                                        </ul>
                                      </Box>
                                    </Box>

                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab.realityscaleheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.realityscalelist?.map(
                                            (val, id) => (
                                              <li key={id}>
                                                <Typography
                                                  sx={{
                                                    textAlign: "left",
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  {val.list}
                                                </Typography>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </Box>
                                    </Box>

                                    <Box sx={{ marginTop: "15px" }}>
                                      <Typography sx={{ textAlign: "left" }}>
                                        {tab.challangeheading}
                                      </Typography>
                                      <Box sx={{ marginTop: "10px" }}>
                                        <ul style={{ textAlign: "left" }}>
                                          {tab.chanllangelist?.map(
                                            (val, id) => (
                                              <li key={id}>
                                                <Typography
                                                  sx={{
                                                    textAlign: "left",
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  {val.list}
                                                </Typography>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Carousel>
                        </Box>
                      </Box>

                      {/* automotive advantage ends */}
                      <Box
                        sx={{
                          mt: 6,
                          paddingBottom: "3rem",
                        }}
                      >
                        {/* <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={6} md={4} lg={6}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "start",
                              }}
                            >
                              <Typography
                                variant="h2"
                                fontWeight={500}
                                fontSize={35}
                              >
                                The Reality Scale Transformation
                              </Typography>{" "}
                              <Typography
                                variant="p"
                                fontWeight={100}
                                color={"#fff"}
                              >
                                Our XR Immersive Solutions are designed to be:
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mt: 3,
                              }}
                            >
                              <ol
                                style={{
                                  color: "#fff",
                                  marginTop: "12px",
                                }}
                              >
                                <li>
                                  <b>Intuitive: </b> Minimal training required,
                                  accessible to team members at all technical
                                  levels
                                </li>
                                <li className="mt-2">
                                  <b>Flexible: </b> Modular approach for
                                  customization to meet unique industry needs
                                </li>
                                <li className="mt-2">
                                  <b>Scalable: </b> Solutions that grow with
                                  your business, from startups to global
                                  enterprises
                                </li>
                                <li className="mt-2">
                                  <b>AI-Enhanced: </b> Advanced AI features like
                                  object recognition and predictive analytics
                                </li>
                                <li className="mt-2">
                                  <b>Innovative: </b> Enhanced cross-platform
                                  and cloud experiences leveraging our ecosystem
                                </li>
                                <li className="mt-2">
                                  <b>Future-Proof: </b> Continuous innovation
                                  ensuring your XR solutions evolve with
                                  technology
                                </li>{" "}
                              </ol>
                            </Box>{" "}
                          </Grid>
                          <Grid item xs={6} md={8} lg={6}>
                            <img src={immersivetransformation} width={"100%"} />
                          </Grid>
                        </Grid> */}
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography
                            variant="h2"
                            fontWeight={500}
                            fontSize={35}
                          >
                            Experience the Future Today
                          </Typography>{" "}
                          <Typography variant="p" fontWeight={100}>
                            The XR revolution is here. Reality Scale transforms
                            science fiction concepts into tangible business
                            solutions.
                          </Typography>{" "}
                          <Box>
                            <Typography variant="h6">Imagine:</Typography>
                            <ul
                              style={{
                                color: "#fff",
                                marginTop: "12px",
                              }}
                            >
                              <li>
                                Walking through your product designs before
                                they're built
                              </li>
                              <li className="mt-2">
                                Conducting risk-free training simulations that
                                feel incredibly accurate
                              </li>{" "}
                              <li className="mt-2">
                                Providing customers with experiences that
                                seamlessly blend digital and physical worlds{" "}
                              </li>
                            </ul>
                          </Box>{" "}
                        </Box>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography color={"#fff"} variant="p">
                            Partner with Reality Scale to unlock the full
                            potential of XR technology for your business.
                            Contact us today to discover how our XR Immersive
                            Solutions can drive your industry's innovation,
                            efficiency, and growth.
                          </Typography>
                        </Box>{" "}
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Immersion;
