import { Box, Typography } from "@mui/material";
import Aos from "aos";
import React, { useState } from "react";
import "./index.css";
import featureData from "../../assests/data/constant";

const Keyfeature = () => {
  Aos.init();

  const [featureImage, setFeatureImage] = useState(featureData.dataType[2]);

  const [show, setShow] = useState("");
  const onchange = (val) => {
    setFeatureImage(val);
    setShow(val.id);
  };

  return (
    <div>
      <div className="container-fluid bg-black featuresection_alignment">
        <div
          className="container feature_spacing"
          style={{
            backgroundImage: `url(${featureImage.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ backgroundColor: "#00000049", height: "100%" }}>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                textAlign={"center"}
                color={"#fff"}
                variant="h4"
                sx={{
                  fontSize: "2rem",
                  fontWeight: 500,
                }}
              >
                Features
              </Typography>
              <h1 className="keyfet text-center mt-3 mb-5  ">
                Reshaping Digital
                <span className="mil-thin mx-2 text-white">Frontiers</span>
              </h1>
            </div>

            <Box className="feature_bannertext">
              <div
                className="list"
                data-aos="fade-down"
                data-aos-offset="20"
                data-aos-delay="50"
                data-aos-duration="800"
                data-aos-easing="ease-in-out"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top"
              >
                <div className="item banner_data">
                  <div className="content">
                    <h1 className="image_heading">
                      {featureImage.heading}
                      {featureImage.head}
                    </h1>
                    <div className="des mt-3 d-flex">
                      <span
                        className="fw-bold mx-1 text_size "
                        style={{
                          color: "#ffd94a",
                        }}
                      >
                        {featureImage.title}
                      </span>{" "}
                      <h6
                        style={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: 300,
                          marginBottom: 0,
                        }}
                      >
                        {featureImage.sub_title}
                      </h6>
                    </div>
                    <br />
                    <div className="d-flex">
                      <span
                        className="fw-bold mx-1 text_size"
                        style={{
                          color: "#ffd94a",
                        }}
                      >
                        {featureImage.title2}
                      </span>{" "}
                      <h6
                        style={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: 300,
                          marginBottom: 0,
                        }}
                      >
                        {featureImage.sub_title2}
                      </h6>
                    </div>{" "}
                    <br />
                    <div className="des d-flex">
                      <span
                        className="fw-bold mx-1 text_size"
                        style={{
                          color: "#ffd94a",
                        }}
                      >
                        {featureImage.title3}
                      </span>{" "}
                      <h6
                        style={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: 300,
                          marginBottom: 0,
                        }}
                      >
                        {featureImage.sub_title3}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="system_screen">
                <div className="imagethumbnail_wrapper">
                  <div
                    className="row"
                    style={{
                      gap: "20px",
                    }}
                  >
                    {featureData &&
                      featureData.dataType.map((val, id) => (
                        <div
                          className="col-sm-2"
                          key={id}
                          style={{ flex: "0 0 auto", cursor: "pointer" }}
                          onClick={() => onchange(val)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${val.image})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              border:
                                featureImage === val
                                  ? "3px solid #ffd94a"
                                  : "1px solid #ffd94a",
                            }}
                            className="thumbnail_imagedata"
                          ></div>
                          <Typography className="featurethumbnail_heading">
                            {val.heading}
                          </Typography>{" "}
                          <Typography className="featurethumbnail_heading">
                            {val.head}
                          </Typography>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="mobile_thumbnailwrapper">
                {featureData &&
                  featureData.dataType.map((val, id) => (
                    <div key={id} onClick={() => onchange(val)}>
                      <div
                        style={{
                          backgroundImage: `url(${val.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          border:
                            featureImage === val
                              ? "1.5px solid #ffd94a"
                              : "0.5px solid #ffd94a",
                        }}
                        className="mobile_thumbnailimage"
                      ></div>
                      <Typography className="featurethumbnail_heading">
                        {val.heading}
                      </Typography>{" "}
                      <Typography className="featurethumbnail_heading">
                        {val.head}
                      </Typography>
                    </div>
                  ))}
              </div>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Keyfeature;
