import { Box, Stack, Tab, Tabs } from "@mui/material";
import Aos from "aos";
import React, { useState } from "react";
import RealityScaleProjects from "../../components/realityscaleproject";
import "./index.css";
const Portfolio = () => {
  Aos.init();
  const [value, setValue] = useState("All");

  const handleChange = (val) => {
    setValue(val);
  };

  const tabsSection = [
    {
      label: "All",
    },
    {
      label: "VR/AR",
    },
    {
      label: "Automotive",
    },
    {
      label: "architecture",
    },
  ];

  return (
    <div className="">
      <Box className="mil-content">
        <div id="swupMain" className="mil-main-transition ">
          {/* <div className="bg-black mil-dark-bg page_alignment "> */}
          <div className="bg-black page_alignment ">
            <div className="mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container section_alignment">
                    <ul
                      className="mil-breadcrumbs mil-light"
                      style={{ padding: "0" }}
                    >
                      <li>
                        <a href="/">Homepage</a>
                      </li>
                      <li>
                        <a href="#">Portfolio</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>

      <Box className="screen_tabs">
        <div className="backgroundvlack tabsection_alignment">
          <div className="mil-p-120-30 productsection_alignment">
            <Box sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Tabs value={value} variant="scrollable" scrollButtons="auto">
                  {tabsSection.map((val, i) => (
                    <Tab
                      label={val.label}
                      key={i}
                      onClick={() => {
                        handleChange(val.label);
                      }}
                      sx={{
                        color: value === val.label ? "#ffd94a" : "#fff",
                        borderBottom:
                          value === val.label
                            ? "2px solid #ffd94a"
                            : "1px solid transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: { xs: 180, lg: 200 },
                      }}
                    />
                  ))}
                </Tabs>
              </Stack>
            </Box>
            <Box>
              <RealityScaleProjects value={value} />
            </Box>
          </div>
        </div>
      </Box>
      <div className="backgroundvlack p-3 mobile_selection">
        <Tabs value={value} variant="scrollable" scrollButtons="auto">
          {tabsSection.map((val, i) => (
            <Tab
              label={val.label}
              key={i}
              onClick={() => {
                handleChange(val.label);
              }}
              sx={{
                color: value === val.label ? "#ffd94a" : "#fff",
                borderBottom:
                  value === val.label
                    ? "2px solid #ffd94a"
                    : "1px solid transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: { xs: 180, lg: 200 },
              }}
            />
          ))}
        </Tabs>
        <Box>
          <RealityScaleProjects value={value} />
        </Box>
      </div>
    </div>
  );
};

export default Portfolio;
