import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
const UnheardGraduates = () => {
  let location = useLocation();

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    if (location.state) {
      setPageData(location.state.value);
    }
  }, [location.state]);

  return (
    <>
      <div className="">
        <Box className="mil-content">
          <div id="swupMain" className="mil-main-transition ">
            <div className="bg-black  page_alignment ">
              <div className="mil-inner-banner mainpage_alignment">
                <div className="mi-invert-fix">
                  <div className="mil-banner-content mil-up">
                    <div className="mil-animation-frame">
                      <div
                        className="mil-animation mil-position-1 mil-scale"
                        data-value-1="7"
                        data-value-2="1.6"
                        style={{
                          transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                        }}
                      >
                        <div className="mil-dodecahedron">
                          {[...Array(12)].map((_, index) => (
                            <div key={index} className="mil-pentagon">
                              {[...Array(5)].map((_, index) => (
                                <div key={index}></div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="container section_alignment">
                      <ul
                        className="mil-breadcrumbs mil-light "
                        style={{
                          padding: "0",
                          position: "relative",
                          zIndex: 999,
                        }}
                      >
                        <li>
                          <a href="/">Homepage</a>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="#">
                            The Unheard Graduates: India's Humanities Crisis and
                            the Silent Struggle for Employment
                          </a>
                        </li>
                      </ul>
                      <h1 className="mil-muted" style={{ color: "#ffd94a" }}>
                        The Unheard Graduates:{" "}
                        <span className="mil-thins" style={{ color: "white" }}>
                          India's
                        </span>
                        <br />
                        Humanities Crisis
                        <span className="mil-thins" style={{ color: "white" }}>
                          {" "}
                          and the Silent
                        </span>{" "}
                        <br />
                        Struggle for
                        <span className="mil-thins" style={{ color: "white" }}>
                          {" "}
                          Employment
                        </span>
                      </h1>
                      <div>
                        <a
                          href="https://www.linkedin.com/in/yagyanseni-swain-5533201a0/details/skills/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography color={"#fff"} fontSize={12}>
                            Author: Yagyanseni Swain
                          </Typography>
                        </a>
                      </div>
                    </div>{" "}
                    <div className="container">
                      <Box
                        sx={{
                          mt: 4,
                        }}
                      >
                        <Box
                          sx={{
                            mt: 1,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            The worth of humanities degrees is increasingly
                            being called into question as graduation becomes
                            overtly equal to difficulty in finding stable
                            employment. This trend has grown especially more and
                            more in India, where great numbers of young people
                            leave college armed with degrees but unprepared with
                            the practical skills demanded of employers.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            The Indian education industry has bloomed with a
                            valuation of $117 billion, and this is expected to
                            grow to $225 billion by 2025. But at the same time,
                            huge investments have led to a growing number of
                            poor quality institutions offering degrees that do
                            not imbue students with marketable skills. A study
                            by Wheebox states that as per the Economic Survey
                            2023-24, an average of only 51.25% of Indians have
                            graduated, not only that An unemployment rate of
                            9.2% as of June 2024 is what India portrays. This
                            demands a revolution in the education sector
                            desperately.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Students across the world are questioning whether
                            their degree would yield a good return on
                            investment. In the United States, these maladies
                            have prompted government inquiries into for-profit
                            schools. The International Labour Organization
                            reported that the global unemployment rate was 5.8%
                            in 2022. In this scenario, it becomes an issue of
                            greater importance that the female unemployment rate
                            stood at 18.5%. These statistics stress the need for
                            education reform so that the skill gap is bridged
                            and employability is improved.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            As a humanities graduate myself, and I speak for my
                            fellow strugglers as well, I have experienced the
                            challenges of entering the job market. Despite
                            approaching numerous start-ups and showcasing my
                            skills, I struggled with inconsistent and low
                            salaries. Many freshers face similar issues, as our
                            courses often lack practical training and use
                            outdated syllabi.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Because of technology and new challenges, the search
                            process itself has changed. Candidates now more
                            often utilize AI tools to prepare their resumes.
                            Similarly, employers use applicant tracking systems,
                            which are AI-powered, in screening applications. The
                            trend risks creating homogenized candidates and
                            filtering in qualification inadvertently.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            But hope is not yet lost. Organizations such as
                            Reality Scale realize the magnitude of the graduates
                            in humanities and avail an avenue for this
                            exponential growth. Employees from diverse
                            backgrounds are granted the opportunity to acquire
                            new skills at Reality Scale, which will help them
                            contribute meaningfully to the organization. It
                            believes that with the right training, any skill can
                            be gained, regardless of one's background.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Though we can speak of few skill development
                            initiatives such as National Skill Development
                            Corporation and Pradhan Mantri Kaushal Vikas Yojana
                            to induce skill development, whose outcomes are yet
                            to be reviewed and yet to be judged.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            Practical training should be incorporated into
                            curriculum agendas, and the usefulness will only be
                            contributed when curricula will be further aligned
                            and blended with industry needs. Advanced training
                            in common applications such as Microsoft Office and
                            Google Workplace, along with new-age AI tools, could
                            provide students with precious, transferable skills,
                            eagerly sought in all industries.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            The Indian education system is disturbed in all
                            divisions, and in this case, the humanities
                            graduates are in a more terrible scenario. The STEM
                            performers may be more industry-skilled, as well,
                            but the humanities students regularly stay
                            unprepared for the market. This does not mean that
                            the humanities graduates have no value or nothing to
                            hope for.
                          </Typography>
                        </Box>{" "}
                        <Box
                          sx={{
                            mt: 3,
                          }}
                        >
                          <Typography variant="p" color={"#fff"}>
                            The primary aim of the humanities graduate should be
                            to develop a mix of soft and hard skills to make
                            themselves more employable. To be added to the
                            skills immediately applicable and in demand are
                            critical thinking, communication, and flexibility
                            are strengths of many humanities programs. Rather
                            than assuming that these graduates are so readily
                            disposable, companies should welcome the new
                            insights as well as the very new concepts these
                            graduates bring to the table. So the challenge still
                            was to bridge the gap between humanities education
                            and market demands. This will unlock the potential
                            of these graduates. It is a huge challenge, but so
                            is the opportunity to build a more diverse,
                            empathetic, and intellectually cross-cutting
                            professional landscape.
                          </Typography>
                        </Box>
                      </Box>{" "}
                      <Box
                        sx={{
                          mt: 3,
                        }}
                      >
                        <Box>
                          <Typography
                            color={"#ffd94a"}
                            variant="h1"
                            fontSize={30}
                            fontWeight={400}
                          >
                            Source
                          </Typography>
                        </Box>
                        <Box>
                          <ul
                            style={{
                              margin: 0,
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          >
                            <li
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <Link
                                to="https://scholarlykitchen.sspnet.org/2023/12/14/humanities-and-jobs-data-whats-the-real-story/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#fff",
                                }}
                              >
                                <Typography>
                                  scholarlykitchen.sspnet.org
                                </Typography>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://analyticsindiamag.com/ai-origins-evolution/indias-obsession-with-stem-is-creating-a-generation-of-jobless-graduates/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#fff",
                                }}
                              >
                                <Typography>analyticsindiamag.com</Typography>
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                to="https://www.indiatoday.in/education-today/latest-studies/story/only-51-indian-graduates-job-ready-in-employability-crisis-economic-survey-2570363-2024-07-22"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#fff",
                                }}
                              >
                                <Box
                                  sx={{
                                    mt: 1,
                                  }}
                                >
                                  <Typography>India Today</Typography>
                                </Box>
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                to="https://www.business-standard.com/india-news/worthless-degrees-are-creating-an-unemployable-generation-in-india-123041800098_1.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#fff",
                                }}
                              >
                                <Box
                                  sx={{
                                    mt: 1,
                                  }}
                                >
                                  <Typography>business-standard.com</Typography>
                                </Box>
                              </Link>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default UnheardGraduates;
