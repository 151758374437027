import image1 from "../../assests/images/Welcome to Reality Scale/Welcome_to_Reality_Scale.webp";
export const componentData = {
  title: "VR, Gaming",
  paragraph: `Rhythm-based VR game synchronized with live music. Uses MIDI integration for real-time note mapping, creating an interactive concert experience in virtual reality.`,
  linkText: "Read more",
  linkTo: "/portfolio",
};

export const secondParaData = {
  title: "Automotive, VR",
  paragraph: `India's first VR car configurator. Offers real-time customization of vehicle features, colors, and accessories in a fully immersive virtual environment.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};

export const thirdParaData = {
  title: " VR, WebGL",
  paragraph: `Unreal Engine-based property viewing system in WebGL. Generates interactive 3D models of properties, allowing for immersive virtual tours accessible through web browsers.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};

export const fourthParaData = {
  title: "Mixed Reality, Automotive",
  paragraph: `MR experience for auto shows. Overlays virtual features on a physical car, allowing exploration of internal components and hidden features through a headset.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};
export const fourthParaData2 = {
  title: "Interactive Automotive Display",
  paragraph: `Showroom-based configurator displaying cars on a 60-inch screen. Unique feature allows sales consultants to control the display using their mobile devices, enhancing the customer experience.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};
export const fourthParaData3 = {
  title: "Renault/Honda/Hyundai Online Cloud Configurator",
  paragraph: `A browser-based car configurator offering detailed customization and remote guided tours, backed by efficient cloud technology for smooth operation.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};

export const fifthParaData = {
  title: "Real-estate, Cloud",
  paragraph: `An innovative cloud solution revolutionizing real estate property exploration. Our platform merges interactivity with personalization, offering users immersive, detailed property experiences from anywhere, at any time..`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};
export const fifthParaData2 = {
  title: "Fashion Project",
  paragraph: `A cloud-powered platform revolutionizing the way fashion is showcased, offering interactive and customizable virtual experiences.`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};
export const fifthParaData3 = {
  title: "Reality Scale Interior",
  paragraph: `A cloud-based solution for exploring & customizing interior designs in real-time, enhancing  estate presentations easy navigation`,
  linkText: "Read more",
  linkTo: "/portfolio ",
};

export const sixthParaData = {
  title: "VR, Gaming",
  paragraph: `Multiplayer VR game designed for live TV broadcast. Enables real-time interaction between players and viewers during a Disney children's show, pushing the boundaries of interactive entertainment.`,
  linkText: "Read more",
  linkTo: "/product",
};
export const sixthParaData2 = {
  title: "AR, Gaming",
  paragraph: `Multiplayer AR bike-riding game for the Olympics. Allows users to ride virtual bikes alongside others in a shared augmented space, creating a unique blend of physical and digital competition.`,
  linkText: "Read more",
  linkTo: "/product",
};
export const sixthParaData3 = {
  title: "Cloud Gaming and NFT Integration",
  paragraph: `Cloud-streamed gaming platform with NFT marketplace. Incorporates realistic metahuman avatars and integrates blockchain technology for in-game asset ownership.`,
  linkText: "Read more",
  linkTo: "/product",
};

export const aboutfile = {
  // subtitle: "Where Innovation Bridges the Digital and Physical Realms.",
  subtitle:
    "Visualize a world where digital product experiences rival the depth and detail of physical interactions. That is what we do.",
  paragraphs: [
    {
      text: "Here, at RealityScale",
      text1: "we’re revolutionizing the digital space with ",
      bold1: "next-gen pioneering technology ",
      text2: "across countless industries, globally.  Combining ",
      bold2: "immersive technology, cloud-computing and machine learning, ",
      text3: "enabled by an array of visualization framework and toolkits.",
    },
    {
      data: "Our arsenal includes ",
      bold: "Digital Twins",
      text1: "that breathe life into data, ",
      bold1: "cloud solutions  ",
      text2: "that make innovation accessible from anywhere, and  ",
      bold2: "AI-driven interactions  ",
      text3: "that anticipate needs before they arise.",
    },
    {
      data: "We stand at the foreground of developing multidimensional digital realities. Pulsing each moment in innovation, tuned with a smartly crafted unified approach. Join us at  ",
      bold: "RealityScale,  ",
      text1:
        "where imagination meets technology, and together, we shape tomorrow's landscapes today.",
    },
  ],
  quote: {
    avatar: image1,
  },
};
