import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowSVG from "../../components/arrow";

const TeamDetails = () => {
  const location = useLocation();
  console.log("location", location);
  const { member } = location.state || {};

  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    const data = localStorage.getItem("selectedMember");
    if (data) {
      setSelectedMember(JSON.parse(data));
    }
  }, []);

  console.log("selectedMember", selectedMember);
  // const member = location.state ? JSON.parse(location.state) : null;
  let navigate = useNavigate();
  // Ensure the page starts at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let a;
  let b;
  let socialMediaLinks =
    member &&
    member.projects &&
    member.projects.map((val, id) => {
      return (a =
        val &&
        val.socialMedia &&
        val.socialMedia.map((value, key) => {
          return (b = value.url);
        }));
    });

  const socialMedia = [
    {
      icon: <AiFillInstagram color="#000" />,
      //   url: socialMediaLinks,
    },
    {
      icon: <FaYoutube color="#000" />,
      //   url: socialMediaLinks,
    },
    {
      icon: <FaTwitter color="#000" />,
      //   url: socialMediaLinks,
    },
    {
      icon: <FaFacebook color="#000" />,
      //   url: socialMediaLinks,
    },
    {
      icon: <FaLinkedin color="#000" />,
      //   url: socialMediaLinks,
    },
  ];

  const handleBackClick = () => {
    navigate("/team");
  };

  return (
    <div className="mil-content">
      <div id="swupMain" className="mil-main-transition" data-swup="0">
        <div className="mil-inner-banner">
          <div
            className="mil-banner-content mil-up"
            style={{
              rotate: "none",
              scale: "none",
              transform: "translate(0px, 0px)",
              opacity: 1,
              translate: "none",
            }}
          >
            <div className="mil-animation-frame">
              <div
                className="mil-animation mil-position-4 mil-dark mil-scale"
                data-value-1="6"
                data-value-2="1.4"
                style={{
                  transform: "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                  WebkitTransform:
                    "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                }}
              >
                <div className="mil-dodecahedron">
                  {[...Array(12)].map((_, index) => (
                    <div key={index} className="mil-pentagon">
                      {[...Array(5)].map((_, index) => (
                        <div key={index}></div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="container section_alignment">
              <div className="">
                <ul className="mil-breadcrumbs" style={{ padding: "0" }}>
                  <li>
                    <a href="/" className="section_head">
                      Homepage
                    </a>
                  </li>
                  <li>
                    <a href="/team" className="section_head">
                      Team
                    </a>
                  </li>{" "}
                  <li>
                    <a href="/" className="section_head">
                      Team Details
                    </a>
                  </li>
                </ul>
                <h1 className="mb-3" style={{ padding: "0" }}>
                  Meet <span className="mil-thin">Our</span>
                  <br /> Creative <span className="mil-thin">Team</span>
                </h1>
              </div>

              <div
                className="mil-link mil-dark mil-arrow-place mil-down-arrow"
                onClick={handleBackClick}
              >
                <span className="section_head">Our team</span>
                <ArrowSVG className="mil-arrow arrow_side " fill="#000000" />
              </div>
              <div className="mt-5">
                {selectedMember &&
                  selectedMember.projects &&
                  selectedMember.projects.map((val, id) => {
                    return (
                      <div className="row mb-4 mt-5" key={id}>
                        <div className="col-sm-5">
                          <img src={val.image} height={"100%"} width={"100%"} />
                        </div>
                        <div className="col-sm-7">
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="member_text"
                          >
                            <Typography
                              variant="h4"
                              fontSize={"40px"}
                              fontWeight={600}
                            >
                              {val.name}
                            </Typography>
                            <Typography
                              variant="h6"
                              color={"#000"}
                              fontSize={"20px"}
                              sx={{ my: 1 }}
                            >
                              {val.role}
                            </Typography>
                            <Typography
                              fontSize={{
                                xs: 20,
                                md: 17,
                                lg: 17,
                              }}
                              fontWeight={{
                                xs: 900,
                                md: 600,
                                lg: 600,
                              }}
                            >
                              {val.para1}
                            </Typography>
                            <Typography
                              marginTop={0.5}
                              fontSize={{
                                xs: 20,
                                md: 17,
                                lg: 17,
                              }}
                              fontWeight={{
                                xs: 800,
                                md: 600,
                                lg: 600,
                              }}
                            >
                              {val.para2}
                            </Typography>
                            <div>
                              <div className="mt-3">
                                {socialMedia.map((item, index) => (
                                  <a
                                    key={index}
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginRight: "15px" }}
                                  >
                                    {item.icon}
                                  </a>
                                ))}
                              </div>
                            </div>
                          </Box>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
