import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import NewData from "../../assests/data/carouselData";
import { COLORS } from "../../utils/defaultColor";
import "./index.css";
import { ArrowForwardIos } from "@mui/icons-material";
import { Player } from "video-react";
const CustomCarousel = () => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const handleThumbnailClick = (index) => {
    setSelectedSlide(index);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedSlide(0);
  };

  let navigate = useNavigate();

  const handleRoute = (slide) => {
    const pageUrl = slide.pageurl;
    navigate(`/portfoliodetails/${pageUrl}`, {
      state: { slide },
    });
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box>
      <div className="bg-black largescreen_sectionheight">
        <div className="container ">
          <div className="carouselsection_data">
            <div className="main_head text-aligns-center d-grid">
              <Typography
                textAlign={"center"}
                color={"#fff"}
                variant="h4"
                sx={{
                  fontSize: "2rem",
                  fontWeight: 500,
                }}
              >
                SHOWCASE
              </Typography>
              <h1 className="keyfet text-center mt-3 mb-5">
                Every Detail
                <span className="mil-thin mx-2 text-white">Counts</span>
              </h1>
            </div>
            <div className="carousel_row">
              <div className="carouselsub_row">
                <div className="d-flex align-items-center justify-content-between tabs_alignment">
                  <div>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      sx={{
                        ".MuiTabs-indicator": {
                          backgroundColor: "#ffd94a",
                        },
                        paddingLeft: { sm: 0, md: 4, lg: 4 },
                      }}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {NewData.tabData.map((val, id) => (
                        <Tab
                          key={id}
                          className="tab_data"
                          label={val.label}
                          sx={{
                            color: activeTab === id ? "#ffd94a" : "grey",
                            fontSize: "16px",
                            fontWeight: 600,
                            padding: "12px 24px",
                            borderBottom:
                              activeTab === id ? "2px solid #ffd94a" : "none",
                            "&.Mui-selected": {
                              color: "#ffd94a",
                            },
                          }}
                        />
                      ))}
                    </Tabs>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <a href="/portfolio">
                      <Typography
                        color={"#ffd94a"}
                        sx={{ cursor: "pointer" }}
                        fontSize={16}
                        fontWeight={600}
                        className="next_button"
                      >
                        Show more <IoIosArrowForward />
                      </Typography>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-10 ">
                    <div>
                      <Carousel
                        selectedItem={selectedSlide}
                        onChange={(index) => setSelectedSlide(index)}
                        autoPlay={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        interval={3000}
                        showArrows={false}
                        swipeable={!isMobile}
                        stopOnHover={true}
                      >
                        {NewData.tabData[activeTab].slides.map((slide) => (
                          <Box
                            sx={{
                              position: "relative",
                              borderRadius: "20px",
                              overflow: "hidden",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 1,
                                borderRadius: "20px",
                              }}
                            />

                            {/* <video
                              playsInline
                              autoPlay
                              muted
                              loop
                              width="100%"
                              height="100%"
                              style={{
                                borderRadius: "20px",
                                cursor: "pointer",
                                position: "relative",
                                zIndex: 0,
                                objectFit: "cover",
                              }}
                              onLoadedMetadata={(event) => {
                                event.target.currentTime = 7;
                              }}
                            >
                              <source src={slide.video} />
                            </video> */}
                            <Player
                              src={slide.video}
                              style={{
                                borderRadius: "20px",
                                cursor: "pointer",
                                position: "relative",
                                zIndex: 0,
                                objectFit: "cover",
                              }}
                              // poster={slide.img}
                              playsInline
                              startTime={7}
                              autoPlay={true}
                              preload="metadata"
                              muted
                            />

                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "5%",
                                transform: "translateY(-50%)",
                                zIndex: 2,
                                color: COLORS.WHITE,
                                textAlign: "left",
                                maxWidth: { lg: "50%", xs: "100%" },
                                px: 2,
                              }}
                            >
                              <Typography
                                color={COLORS.PRIMARY}
                                fontSize={{ lg: 35, xs: 20 }}
                                fontWeight={"900 !important"}
                                mb={2}
                              >
                                {slide.heading}
                              </Typography>
                              <Typography
                                color={COLORS.WHITE}
                                fontSize={{ lg: 14, xs: 10 }}
                                mb={3}
                              >
                                {slide.subheading}
                              </Typography>
                              <Button
                                endIcon={<ArrowForwardIos />}
                                sx={{
                                  border: `1px solid ${COLORS.PRIMARY}`,
                                  color: COLORS.WHITE,
                                  ":hover": {
                                    svg: {
                                      transform: "translateX(4px)",
                                    },
                                    backgroundColor: COLORS.PRIMARY,
                                    color: COLORS.BLACK,
                                  },
                                  "&": {
                                    svg: {
                                      transition: "0.5s ease all",
                                    },
                                  },
                                }}
                                onClick={() => handleRoute(slide)}
                              >
                                Show More
                              </Button>
                            </Box>
                          </Box>
                        ))}
                      </Carousel>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-2 col-lg-2">
                    <div
                      style={{
                        marginTop: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        // gap: "1rem",
                      }}
                      className="thumbnail_section"
                    >
                      {NewData.tabData[activeTab].slides.map((val, id) => (
                        <div
                          key={id}
                          onClick={() => handleThumbnailClick(id)}
                          style={{
                            position: "relative",
                            borderRadius: "5px",
                            cursor: "pointer",
                            padding: "10px",
                            overflow: "hidden",
                            height: "100%",
                          }}
                          className="row  thumbnail_image"
                        >
                          {selectedSlide === id && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                zIndex: 1,
                              }}
                              className="animation_slide"
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: "-100%",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#eeee",
                                  zIndex: 2,
                                  animation: "slideIn 3s forwards",
                                  opacity: 0.1,
                                }}
                              ></div>
                            </div>
                          )}
                          <div className="col-sm-2 col-md-5 col-lg-5">
                            <div
                              style={{
                                backgroundImage: `url(${val.thumbnail})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "2px",
                                height: "100%",
                                width: "100%",
                              }}
                              className="thumb_image"
                            />
                          </div>
                          <div className="col-sm-7">
                            <div className="">
                              <Typography
                                color={"#fff"}
                                fontSize={"15px"}
                                fontWeight={600}
                                className="thumbnail_text"
                              >
                                {val.content}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default CustomCarousel;
