import disneyslide4 from "../../assests/carimages/Disney Pijama Ride VR_Game (1).webp";
import disneyslide5 from "../../assests/carimages/Disney Pijama Ride VR_Game (4).webp";
import {
  default as disneyslide3,
  default as image,
} from "../../assests/carimages/disney_payjamasliderimage.jpg";

import disneythumbnailimage from "../../assests/carimages/disney_pajamathumbnail.jpg";
import olympic1 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (1).webp";
import olympic2 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (2).webp";
import olympic3 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (4).webp";
import olympic from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (5).webp";
import youtholympic from "../../assests/carimages/youth_olympicsliderimage.jpg";
import youtholympicthumbnail from "../../assests/carimages/youth_olympicthumbnailimage.jpg";
import nexon2 from "../../assests/carimages/pexels-helen-alp-46790226-27779253.jpg";
import nexon1 from "../../assests/carimages/pexels-helen-alp-46790226-27779257.jpg";
import nexon from "../../assests/carimages/tatanexon.webp";
import disneyslide2 from "../../assests/images/work/disneyslider/disneyslide2.png";
import mahindra1 from "../../assests/images/work/mahindra/back.jpg";
import mahindra from "../../assests/images/work/mahindra/light.jpg";
import mahindra2 from "../../assests/images/work/mahindra/opengate.jpg";
import mahindra3 from "../../assests/images/work/mahindra/wheel.jpg";
import screencraft from "../../assests/carimages/screencraft.png";
import mahindrasliderimage from "../../assests/carimages/mahindraslider_image.jpg";
import mahindrathumbnailimage from "../../assests/carimages/mahindraslider_image.jpg";
let NewData = {
  tabData: [
    {
      label: "VR/AR",
      slides: [
        {
          pageurl: "disney-pyjama-ride",
          projectHeading: "Disney",
          projectsecongheading: "Pyjama Ride",
          id: 0,
          img: image,
          thumbnail: disneythumbnailimage,
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/disneyPajama.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 1",
          content: "Disney Pyjama Ride",
          heading: "Disney Pyjama Ride",
          client: "Disney",
          subheading:
            "Rhythm-based VR game synchronized with live music. Uses MIDI integration for real-time note mapping, creating an interactive concert experience in virtual reality.",
          productDetails: {
            bannerImage: disneyslide2,
            brand: "",
            slider1Image: [
              {
                img: disneyslide2,
              },
              {
                img: disneyslide3,
              },
              {
                img: disneyslide4,
              },
              {
                img: disneyslide5,
              },
            ],
            paraHeading: "Disney Pyjama Ride",
            heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
            heading2: "Project Overview",
            para1:
              "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
            para2:
              "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
            impact: "Impact",
            impactberief:
              "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team overcame several challenges to bring this project to life:",
            featurelist: [
              {
                list: "Intuitive controls for young players",
              },
              {
                list: "Multiple levels with varied terrain and challenges",
              },
              {
                list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
              },
              {
                list: "Real-time gameplay recording capabilities",
              },
            ],

            technicallist: [
              {
                head: "Dynamic Track System :",
                list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
              },
              {
                head: "Real-time Recording :",
                list: "We implemented a system for capturing the VR gameplay in real-time.",
              },
              {
                head: "Expansive, Seamless World :",
                list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
              },
              {
                head: "Dynamic Event Triggering :",
                list: "We created a flexible system for triggering real-time events during gameplay.",
              },
              {
                head: "VR Optimization :",
                list: "The entire experience was optimized for smooth VR performance.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
            // voiceoverheading: "Voice over scripts",
            // scriptheading: "Script 1",
            // scriptlist: [
            //   {
            //     list: "Step into the world of Pyjama Ride, our thrilling VR project that turns virtual sledding into an unforgettable adventure for kids!",
            //   },
            //   {
            //     list: "Our team tackled the challenge of creating an intuitive control system that's easy for children to use, yet delivers an exhilarating experience. We developed a dynamic track system, allowing precise control over speed and shape to keep the excitement high.",
            //   },
            //   {
            //     list: "One of our proudest achievements is the vast, seamless world we built. Picture a multi-level mountain landscape filled with surprising events like collapsing rocks and flowing lava. It's an endless virtual roller coaster!",
            //   },
            //   {
            //     list: "But here's the real magic: our real-time recording system captures every thrilling moment, bringing the virtual experience into the real world.",
            //   },
            //   {
            //     list: "Pyjama Ride isn't just a game – it's a journey into the possibilities of VR. At Reality Scale, we're not just creating games; we're crafting dreams and making them virtually real.",
            //   },
            // ],
            // secondscriptheading: "Script 2",
            // secondscriptlist: [
            //   {
            //     list: "Developing Pyjama Ride was quite the roller coaster – pun intended. We set out to create a VR sledding game for kids, and boy, did we hit some bumps along the way.",
            //   },
            //   {
            //     list: "Our biggest challenge? Nailing those controls. We needed them simple enough for kids but still fun. Cue countless hours tweaking physics and chasing bugs – like when the sledge decided it wanted to be a rocket ship instead.",
            //   },
            //   {
            //     list: "Building a massive, seamless VR world nearly melted our machines. We got pretty creative with optimization just to keep the frame rate above slideshow levels.",
            //   },
            //   {
            //     list: "And don't get me started on the real-time event system. Syncing falling rocks and lava flows across the network? Let's just say it involved a lot of late-night debugging and energy drinks.",
            //   },
            //   {
            //     list: "In the end, we made something that kids enjoyed, even if it wasn't perfect. The real win was everything we learned about VR dev for young audiences. That knowledge? It's gold for our future projects.",
            //   },
            // ],
          },
        },
        {
          pageurl: "youth-olympic-project",
          projectHeading: "Youth",
          projectsecongheading: "Olympic Project",
          id: 1,
          img: youtholympic,
          thumbnail: youtholympicthumbnail,
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/youthOlympic.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Youth Olympics Project",
          heading: "Youth Olympics Project",
          subheading:
            "India's first VR car configurator. Offers real-time customization of vehicle features, colors, and accessories in a fully immersive virtual environment.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "Youth Olympics Project",
            heading1: "BMX Olympic Showdown: AR Racing Reimagined",
            heading2: "Project Overview",
            para1:
              "BMX Olympic Showdown, an exhilarating multiplayer Augmented Reality racing game developed for the Winter Youth Olympics show floor. This project exemplifies our ability to create quick and fun experiences that engage and excite users in high-stakes environments using already existing technologies and at rapid speed.",
            para2:
              "The game was made to transform any floor into a virtual racing arena, allowing multiple players to compete in thrilling BMX races using iPad devices. The game combines the excitement of BMX racing with the immersive capabilities of XR and gives users an easy and competitive experience.",
            impact: "Impact",
            impactberief:
              "Olympic AR BMX demonstrates our capability to create engaging, accessible AR content for large-scale events rapidly. The game was enjoyed by thousands of participants during the Youth Olympics, bringing the excitement of BMX racing to life through the innovative use of existing XR technologies. This project showcases our expertise in swift development of impactful AR solutions that perform flawlessly in demanding, high-profile environments.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "Seamless multiplayer connectivity for real-time racing",
              },
              {
                list: "Intuitive controls optimised for iPad devices",
              },
              {
                list: "AR-powered course overlay on any physical floor",
              },
              {
                list: "High-quality animations and visual effects",
              },
              {
                list: "Quick-to-learn, challenging-to-master gameplay",
              },
            ],

            technicallist: [
              {
                head: "Efficient Multiplayer System:",
                list: "We utilised web sockets for seamless device connectivity, enabling multiple race sessions to run simultaneously on the show floor.",
              },
              {
                head: "Simple AR Synchronization:",
                list: "Our team implemented a straightforward AR scanning system to sync the game environment across all connected devices quickly.",
              },
              {
                head: "Low-Latency Action Communication:",
                list: " We created a streamlined system for transmitting and synchronising player actions across devices in real time.",
              },
              {
                head: "Flexible Session Management:",
                list: "Our solution allowed for easy setup of multiplayer sessions using iPads, facilitating quick turnover between participant groups",
              },
              {
                head: "Optimized AR Performance:",
                list: "The experience was fine-tuned to deliver smooth, engaging visuals and animations on iPad devices within a short development timeframe.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
        {
          pageurl: "mahindra-project",
          projectHeading: "Mahindra",
          projectsecongheading: "Project",
          id: 1,
          img: mahindrasliderimage,
          thumbnail: mahindrathumbnailimage,
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/mahindra.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Mahindra Automotive Project",
          heading: "Mahindra Automotive VR Configurator",
          subheading:
            "Pioneering VR showroom allowing customers to explore, customize, and interact with virtual cars.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "",
            heading1: "Mahindra Automotive VR Configurator",
            heading2: "Project Overview",
            para1:
              "In the early days of virtual reality, Reality Scale embarked on a groundbreaking journey to transform the automotive retail experience. We were tasked with creating a fully immersive VR showroom for a leading car manufacturer, pushing the boundaries of what was possible with this emerging technology.",
            para2:
              "In the early days of virtual reality technology, we undertook an ambitious project to create a VR showroom for a leading automotive company. We aimed to develop an immersive, interactive environment where users could explore all car variants and models as if they were in a physical showroom. This project pushed the boundaries of VR technology at the time, challenging us to achieve high-quality rendering, intuitive controls, and realistic interactions within a virtual space.",
            impact: "Impact",
            impactberief:
              "This Automotive VR Project revolutionised how car manufacturers showcase their products, providing an experience that surpassed traditional showrooms. Its success triggered an industry-wide transformation, with our client integrating their entire lineup into VR and competitors racing to develop even more advanced solutions.",
            impactberiefone:
              "The project marked our entry into the enterprise space, laying the groundwork for our current solutions, including our flagship NeoReal projects. Despite a temporary setback due to the pandemic, enterprise VR is now resurging across multiple sectors.",
            impactberieftwo:
              "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "High-fidelity VR rendering of multiple car models and variants",
              },
              {
                list: "Intuitive single-controller interface for user interactions",
              },
              {
                list: "Secondary controller for sales consultant assistance",
              },
              {
                list: "Realistic car interactions (opening doors, adjusting seats, etc.)",
              },
              {
                list: "Dynamic car customisation (model selection, color changes)",
              },
              {
                list: "Complementary mobile application for users uncomfortable with VR movement",
              },
            ],

            technicallist: [
              {
                head: "Rendering Quality",
                list: "We overcame significant technical challenges to achieve high-quality rendering that accurately represented the cars' details and finishes.",
              },
              {
                head: "Intuitive Controls",
                list: " After extensive experimentation, we developed a single-controller interface that allowed users to navigate and interact with the virtual environment naturally.",
              },
              {
                head: "Realistic Interactions",
                list: "We successfully replicated real-world car interactions in VR, enabling users to open doors, sit in seats, adjust seating positions, and even open the sunroof.",
              },
              {
                head: "Customization Options",
                list: "Users could easily switch between different car models, variants, and colors, providing a comprehensive exploration experience.",
              },
              {
                head: "Sales Consultant Integration",
                list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
              },
              {
                head: "Scalable Car Framework",
                list: "We developed a flexible framework that allowed for easy addition of new car models to the system, ensuring long-term scalability.",
              },
              {
                head: "Accessibility Solution",
                list: " Recognizing that some users struggled with VR movement, we created a ctary omplemenmobile application to ensure the experience was accessible to all potential customers",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Automotive",
      slides: [
        {
          pageurl: "mahindra-project",
          projectHeading: "Mahindra",
          projectsecongheading: "Project",
          id: 1,
          img: mahindrasliderimage,
          thumbnail:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/mahindra.jpg",
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/mahindra.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Mahindra Automotive Project",
          heading: "Mahindra Automotive VR Configurator",
          subheading:
            "Pioneering VR showroom allowing customers to explore, customize, and interact with virtual cars.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "",
            heading1: "Mahindra Automotive VR Configurator",
            heading2: "Project Overview",
            para1:
              "In the early days of virtual reality, Reality Scale embarked on a groundbreaking journey to transform the automotive retail experience. We were tasked with creating a fully immersive VR showroom for a leading car manufacturer, pushing the boundaries of what was possible with this emerging technology.",
            para2:
              "In the early days of virtual reality technology, we undertook an ambitious project to create a VR showroom for a leading automotive company. We aimed to develop an immersive, interactive environment where users could explore all car variants and models as if they were in a physical showroom. This project pushed the boundaries of VR technology at the time, challenging us to achieve high-quality rendering, intuitive controls, and realistic interactions within a virtual space.",
            impact: "Impact",
            impactberief:
              "This Automotive VR Project revolutionised how car manufacturers showcase their products, providing an experience that surpassed traditional showrooms. Its success triggered an industry-wide transformation, with our client integrating their entire lineup into VR and competitors racing to develop even more advanced solutions.",
            impactberiefone:
              "The project marked our entry into the enterprise space, laying the groundwork for our current solutions, including our flagship NeoReal projects. Despite a temporary setback due to the pandemic, enterprise VR is now resurging across multiple sectors.",
            impactberieftwo:
              "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "High-fidelity VR rendering of multiple car models and variants",
              },
              {
                list: "Intuitive single-controller interface for user interactions",
              },
              {
                list: "Secondary controller for sales consultant assistance",
              },
              {
                list: "Realistic car interactions (opening doors, adjusting seats, etc.)",
              },
              {
                list: "Dynamic car customisation (model selection, color changes)",
              },
              {
                list: "Complementary mobile application for users uncomfortable with VR movement",
              },
            ],

            technicallist: [
              {
                head: "Rendering Quality",
                list: "We overcame significant technical challenges to achieve high-quality rendering that accurately represented the cars' details and finishes.",
              },
              {
                head: "Intuitive Controls",
                list: " After extensive experimentation, we developed a single-controller interface that allowed users to navigate and interact with the virtual environment naturally.",
              },
              {
                head: "Realistic Interactions",
                list: "We successfully replicated real-world car interactions in VR, enabling users to open doors, sit in seats, adjust seating positions, and even open the sunroof.",
              },
              {
                head: "Customization Options",
                list: "Users could easily switch between different car models, variants, and colors, providing a comprehensive exploration experience.",
              },
              {
                head: "Sales Consultant Integration",
                list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
              },
              {
                head: "Scalable Car Framework",
                list: "We developed a flexible framework that allowed for easy addition of new car models to the system, ensuring long-term scalability.",
              },
              {
                head: "Accessibility Solution",
                list: " Recognizing that some users struggled with VR movement, we created a ctary omplemenmobile application to ensure the experience was accessible to all potential customers",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
        {
          pageurl: "renault-project",
          projectHeading: "Renault",
          projectsecongheading: "Project",
          id: 1,
          img: mahindrasliderimage,
          thumbnail:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/renault1.jpg",
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/renault.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Renault Automotive Project",
          heading: "Renault - Cloud Streamed Automotive Configurator",
          subheading:
            "Cloud-streamed car configurator letting users explore Renault models remotely.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "",
            heading1: "Renault - Cloud Streamed Automotive Configurator",
            heading2: "Project Overview",
            para1:
              "Renault Reality Streamed Configurator represents a groundbreaking leap in automotive digital retail. Building on our expertise in cloud gaming and previous automotive projects, we developed India's first cloud-streamed car configurator, setting a new benchmark in the industry. This innovative solution showcases our ability to push technological boundaries and deliver cutting-edge solutions that transform how customers interact with automotive products online.",
            para2:
              "The Renault Reality Streamed Configurator is a state-of-the-art, cloud-based platform that allows users to explore and customize Renault vehicles in photorealistic quality from any internet-connected device. Leveraging our proprietary WebRTC-based streaming solution, we developed a system capable of handling over 10,000 concurrent users while maintaining high visual fidelity and zero loading times. This project showcased multiple Renault models and incorporated advanced features and interactions that elevate the car exploration experience to new heights.",
            impact: "Impact",
            impactberief:
              "The Renault Reality Streamed Configurator revolutionised automotive digital experiences, earning Renault multiple awards, including the IDMA Gold Medal for Best Branded Content. As India's first cloud-streamed car configurator, Renault has positioned itself at the forefront of automotive innovation, significantly expanding its digital reach and redefining customer engagement. The platform's scalable, cost-efficient architecture allowed Renault to serve thousands of concurrent users, demonstrating the power of cloud-based solutions in automotive retail.",
            impactberiefone:
              "This project laid the foundation for Reality Scale's future innovations, including our NeoReal platform. By successfully merging cloud gaming technology with automotive visualisation, we solved our client's immediate challenges and pioneered a new direction for the entire industry. The configurator's success set a new benchmark for digital automotive experiences, influencing future developments and solidifying Reality Scale's position as a leader in innovative digital visualisation solutions.",
            // impactberieftwo:
            //   "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
            featureheading: "Key Feature",
            technical: "Project Highlights:",
            // technicalsubheading:
            //   "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "Cloud-streamed, photorealistic 3D car models with zero loading time",
              },
              {
                list: "Comprehensive exploration of all variants and colors for multiple Renault models",
              },
              {
                list: "Intuitive and user-friendly interface for easy navigation and customization",
              },
              {
                list: "Advanced visualization of car features, including seat configurations and smart technologies",
              },
              {
                list: "Scalable architecture supporting 10,000+ concurrent users",
              },
              {
                list: "Optimized for high performance and low operational costs",
              },
              {
                list: "Seamless accessibility from any internet-connected device",
              },
            ],

            technicallist: [
              {
                // head: "Rendering Quality",
                list: "Pioneered India's first cloud-streamed car configurator.",
              },
              {
                // head: "Intuitive Controls",
                list: "Expanded and optimised our existing WebRTC-based streaming solution for automotive use.",
              },
              {
                // head: "Realistic Interactions",
                list: "Developed a highly scalable system capable of supporting massive concurrent user loads.",
              },
              {
                // head: "Customization Options",
                list: "Implemented innovative interactions and visualisations to showcase car features in unprecedented detail.",
              },
              {
                // head: "Sales Consultant Integration",
                list: "Optimised the entire system for maximum visual quality at minimal operational cost.",
              },
              {
                // head: "Scalable Car Framework",
                list: "Successfully integrated and showcased multiple Renault car models within the platform.",
              },
              {
                // head: "Accessibility Solution",
                list: " Created a foundation for future expansion and adaptation of the technology.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
        {
          pageurl: "honda-project",
          projectHeading: "Honda",
          projectsecongheading: "Project",
          id: 1,
          img: mahindrasliderimage,
          thumbnail:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/thumbnail/honda1.jpg",
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/honda.mp4",
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Honda Automotive Project",
          heading: "Honda Automotive Digital Brochure",
          subheading:
            " Interactive vehicle configurator empowering dealers to showcase cars through touchscreen displays.",
          productDetails: {
            bannerImage: youtholympic,
            client: "Disney",
            brand: "",
            slider1Image: [
              {
                img: olympic,
              },
              {
                img: olympic1,
              },
              {
                img: olympic2,
              },
              {
                img: olympic3,
              },
            ],
            paraHeading: "",
            heading1: "Honda Automotive Digital Brochure",
            heading2: "Project Overview",
            para1:
              "The Remote Controlled Configurator is a comprehensive digital ecosystem that enables dealerships to showcase their entire vehicle lineup on digital signage. Controlled by sales consultants via a custom mobile app, this system redefines customer exploration of vehicles, setting a new standard in interactive product presentation.",
            para2:
              "The project emerged from a critical business need to revolutionize the traditional car buying experience. With showroom space becoming more expensive and customers seeking more flexible methods to experience automobiles, our VR system provided a scalable, cost-effective alternative that could be deployed across various locations. This invention not only solved immediate space restrictions, but also gave significant data insights into consumer preferences and behavior patterns during the car-buying process.",
            impact: "Impact",
            impactberief:
              "This Remote Controlled Configurator revolutionized automotive retail by seamlessly blending digital innovation with showroom engagement. It eliminated inventory constraints while enhancing product showcase capabilities across over 200 dealerships. The project streamlined sales processes and provided valuable customer interaction data, enabling informed decision-making in product development and marketing.",
            impactberiefone:
              "The success of this project, including the seamless integration of Honda's entire catalogue (nine cars, 50 variants, and hundreds of interactions), laid the groundwork for our future cloud-based Neo-Real platform. It cemented Reality Scale's position as a leader in immersive automotive visualization, showcasing our ability to deliver complex, scalable solutions that address real-world business challenges and our commitment to continuous innovation.",
            // impactberieftwo:
            //   "By pioneering this technology in the automotive industry, we established ourselves as leaders in applying immersive technologies to real-world business challenges, setting the stage for innovations across various industries",
            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading:
              "Our team leveraged existing technologies to deliver this project rapidly:",
            featurelist: [
              {
                list: "High-fidelity digital brochure showcasing entire vehicle lineup and variants",
              },
              {
                list: "Remote control functionality via dedicated mobile application",
              },
              {
                list: "Real-time synchronization between mobile app and primary display",
              },
              {
                list: "Interactive elements demonstrating vehicle technologies and dashboard features",
              },
              {
                list: "Modular design adaptable to various car models",
              },
              {
                list: "Comprehensive data collection and analytics",
              },
              {
                list: "Automated distribution system for over 200 dealerships",
              },
            ],

            technicallist: [
              {
                head: "Unified Codebase:",
                list: "Developed a single, efficient codebase powering both the configurator and mobile app.",
              },
              {
                head: "Modular Design:",
                list: "Created a forward-thinking approach allowing easy adaptation to different car models and future requirements.",
              },
              {
                head: "WebSocket Integration:",
                list: "Implemented WebSocket technology to establish robust links between mobile devices and primary displays.",
              },
              {
                head: "CI/CD Pipeline:",
                list: "Engineered an automated distribution system for real-time updates across hundreds of dealerships.",
              },
              {
                head: "Sales Consultant Integration",
                list: " We incorporated a secondary controller for sales consultants, allowing them to assist and guide users through the virtual showroom.",
              },
              {
                head: "Advanced Analytics:",
                list: "Implemented sophisticated data collection mechanisms for valuable user insights.",
              },
              {
                head: "Photorealistic Rendering:",
                list: " Achieved stunning visual fidelity, showcasing vehicles in exquisite detail.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
    {
      label: "Architecture",
      slides: [
        {
          projectHeading: "Scene",
          projectsecongheading: "Craft",
          pageurl: "screen-craft",
          id: 0,
          img: screencraft,
          thumbnail: screencraft,
          title: "Automotive",
          description: "Description for Automotive Image 1",
          video:
            "https://realityscalebucket.s3.eu-north-1.amazonaws.com/sceneCraft.mp4",
          content: "Scene Craft",
          heading: "Scene Craft",
          subheading:
            "Scene Craft combines real-time WebGL with cloud rendering, delivering high-resolution fabric visualisation up to 16K while reducing costs for textile businesses.",
          productDetails: {
            bannerImage: screencraft,
            client: "",
            brand: "",
            slider1Image: [
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
              {
                img: screencraft,
              },
            ],
            paraHeading: "Scene Craft",
            heading1: "Scene Craft: Advanced Fabric Rendering Platform",
            heading2: "Project Overview",
            para1:
              "Scene Craft represents one of Reality Scale's most technologically advanced project. Born from the need for high-quality, cost-effective fabric rendering, this platform pushes the boundaries of what's possible in digital textile visualisation. By leveraging our proprietary hybrid rendering technology, Scene Craft offers an unparalleled combination of real-time interactivity and photorealistic output sets a new industry standard.",
            para2:
              "Scene Craft evolved from our initial WebGL solution, addressing limitations in render quality and high server costs of our real-time interior solutions. Leveraging our research in hybrid rendering, we developed Scene Craft as a SaaS platform for fabric and textile makers. This innovative approach combines real-time WebGL rendering for interactive adjustments with cloud-based high-quality rendering for final outputs, creating a more advanced and scalable product.",
            impact: "Impact",
            impactberief:
              "Scene Craft has revolutionised fabric rendering by combining real-time interactivity with photorealistic output at unprecedented resolutions. Its scalable, cost-effective approach makes high-quality visualisation accessible to businesses of all sizes, accelerating product development and enhancing marketing capabilities. This project delivers superior rendering solutions and showcases Reality Scale's ability to innovate and overcome complex technical challenges, reinforcing our position as industry leaders in digital visualisation.",

            featureheading: "Key Feature",
            technical: "Technical Achievements",
            technicalsubheading: "",
            featurelist: [
              {
                list: "Combines WebGL for real-time manipulation with cloud-based high-quality rendering, offering unmatched flexibility.",
              },
              {
                list: "Produces renders up to 16K resolution, matching V-Ray quality with real-time adjustability.",
              },
              {
                list: "Offers various material types and adjustable properties for extensive customization.",
              },
              {
                list: "Accommodates multiple users simultaneously at reduced costs compared to traditional solutions.",
              },
              {
                list: "Allows scene uploads, user access management, and platform oversight.",
              },
              {
                list: "Enables quick scene adjustments with immediate visual feedback.",
              },
              {
                list: "Provides intuitive controls for texture uploads, property adjustments, and render initiation.",
              },
            ],

            technicallist: [
              {
                head: "Hybrid Rendering Pipeline:",
                list: "Integrated real-time WebGL and high-quality cloud-based rendering, solving synchronisation and data management challenges.",
              },
              {
                head: "Scalable Cloud Architecture: ",
                list: " Built infrastructure handling multiple rendering requests cost-effectively.",
              },
              {
                head: "Real-Time to High-Quality:",
                list: "Developed system translating WebGL adjustments into high-quality render Parameters, ensuring preview-output consistency.",
              },
              {
                head: "Advanced Material System:",
                list: "Created a flexible system that accurately represents complex fabrics in real-time and high-quality renders.",
              },
              {
                head: "High-Resolution Rendering:",
                list: " Engineered rendering up to 16K, balancing quality and rendering time.",
              },
              {
                head: "Scene Management System:",
                list: "Implemented robust backend for efficient scene and user management.",
              },
              {
                head: "Cross-Platform Compatibility:",
                list: " Ensured functionality across devices and browsers, overcoming WebGL and cloud integration challenges.",
              },
            ],
            slider2: [
              {
                img: mahindra,
              },
              {
                img: mahindra1,
              },
              {
                img: mahindra2,
              },
              {
                img: mahindra3,
              },
            ],
          },
        },
      ],
    },
  ],
};
export default NewData;
