import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "./index.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const MyCarousel = ({ member }) => {
  const navigate = useNavigate();

  const teamDetailsPage = (value) => {
    localStorage.setItem("selectedMember", JSON.stringify(value));
    navigate("/team-details");
  };

  return (
    <div className="container">
      <div>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          pauseOnHover={true}
          draggable={false}
        >
          {member &&
            member.map((val, id) => {
              return (
                <a href="/team-details" key={id}>
                  <Card
                    sx={{
                      padding: "10px",
                      margin: "0 10px",
                    }}
                    onClick={() => teamDetailsPage(val)}
                  >
                    <img
                      src={val.image}
                      height="100%"
                      width="100%"
                      draggable={false}
                    />

                    <Box
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Typography
                        fontSize={{
                          xs: 30,
                          md: 25,
                          lg: 30,
                        }}
                        fontWeight={{
                          xs: 700,
                          md: 600,
                          lg: 600,
                        }}
                        color={"#ffd94a"}
                      >
                        {val.name}
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={300}
                        className="teammember_role"
                      >
                        {val.role}
                      </Typography>
                    </Box>
                  </Card>
                </a>
              );
            })}
        </Carousel>
      </div>
    </div>
  );
};

export default MyCarousel;
