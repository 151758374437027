import React from "react";
import "./index.css";
import NotFoundImage from "../../assests/imagenotfound.jpg";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img
        src={NotFoundImage}
        alt="404 Not Found"
        className="not-found-image"
      />
      <h2>Page Not Found</h2>
    </div>
  );
};

export default NotFound;
