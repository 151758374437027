import { Container, Grid, Typography } from "@mui/material";
import Aos from "aos";
import React, { useEffect, useRef } from "react";
import data from "../../assests/data/data";
import Game from "../../views/game";
import "./index.css";

const Transform = () => {
  useEffect(() => {
    if (window.innerWidth > 768) {
      Aos.init();
    }
  }, []);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollPosition = window.pageYOffset;
        parallaxRef.current.style.backgroundPositionY = `${
          scrollPosition * 1
        }px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container-fluid bg-black transformsection_height">
        <div
          style={{
            height: "100%",
          }}
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="50"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top"
        >
          <div className="container transformsection_alignment">
            <div className="mb-5 mobile_headingtext">
              <Typography
                textAlign={"center"}
                color={"#fff"}
                variant="h4"
                sx={{
                  fontSize: "2rem",
                  fontWeight: 500,
                }}
              >
                LET REALITY SCALE
              </Typography>
              <h1 className="keyfet text-center my-2 mb-4">
                Help You
                <span className="mil-thin mx-2 text-white">Transform</span>
              </h1>
              <p className="keyfetE  mobile_text mil-light-soft mil-center text-white">
                how you showcase your products and engage with your customers!
              </p>
            </div>
            <div className="mobileimage_screen">
              <Grid container spacing={2}>
                {data.gameData.map((val, i) => (
                  <Grid item lg={4} xs={12} xl={4} key={i}>
                    <Game val={val} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Transform;
