import React, { useState } from "react";
import "./index.css";
import { Box, Typography, Stack, useMediaQuery } from "@mui/material";
import ArrowSVG from "../../components/arrow";
import { useNavigate } from "react-router-dom";

const Game = ({ val }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsButtonVisible(true);
  };

  const handleMouseLeave = () => {
    setIsButtonVisible(false);
  };

  const phoneMatches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const [state, setState] = useState("");
  const handleRoute = (val) => {
    navigate(`${val.url}`, {
      state: { val },
    });
  };

  return (
    <div className="d-overlay" onClick={() => handleRoute(val)}>
      <a href={val.url}>
        <div
          className="zoom-img transform_image"
          style={{
            backgroundImage: `url(${val.image})`,
            height: phoneMatches ? 250 : 220,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: "100%",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            sx={{
              height: "100%",
              backgroundColor: "#00000055",
              color: "#ffffff",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "end",
              border: "2px solid transparent",
              position: "relative",
              ...(phoneMatches
                ? {}
                : {
                    ":hover": {
                      border: "none",
                      transition: "border .5s, box-shadow .5s",
                      zIndex: 1,
                      backgroundColor: "#00000055",
                      paddingLeft: "10px",
                    },
                  }),
            }}
          >
            <Box
              sx={{
                paddingBottom: phoneMatches ? 2 : 3,
                paddingLeft: phoneMatches ? 3 : 3,
              }}
              className="image_text"
            >
              <Typography
                sx={{ fontSize: 18, fontWeight: 600, color: "#ffd94a" }}
                className="d-text"
              >
                {val.heading}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={0}>
                <Typography
                  sx={{ fontSize: 10, fontWeight: 100 }}
                  className="d-text1"
                >
                  {val.subHeading}
                  <Box
                    className="mil-links mil-muted mil-arrow-place left-align"
                    sx={{
                      display:
                        phoneMatches || isButtonVisible ? "block" : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRoute(val)}
                  >
                    Read More
                    <ArrowSVG className="mil-arrow" />
                  </Box>
                </Typography>
              </Stack>
            </Box>
          </Box>
        </div>
      </a>
    </div>
  );
};

export default Game;
