import React from "react";
import data from "../../assests/data/data";
import ArrowSVG from "../arrow";
import "./index.css";
import navlogo from "../../assests/footer_logo.png";
import { Box, Grid } from "@mui/material";
const Footer = () => {
  return (
    <footer className="mil-dark-back">
      <div className="mi-inverter-fix">
        <div className="container mil-p-120-30 footersection_alignment footer_class">
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-4 mil-mb-60 text_alignment">
              <div className="main-footer__about">
                <p className="footer-widget__text fw-400">
                  Let’s start working together
                </p>

                <h3 className="footer_number text-white">+91 8800291352</h3>
              </div>
              <div className="mil-muted mil-logo mil-up mil-mb-30 mt-4 ">
                <img src={navlogo} style={{ width: "64%" }} alt="logo" />
              </div>
              <div className="mil-subscribe-form mil-up mt-2">
                <a href="/contact">
                  <input
                    type="text"
                    value="Contact Us"
                    disabled
                    readOnly
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      color: "#fff",
                    }}
                  />
                  <button className="mil-button mil-icon-button-sm2 mil-arrow-place">
                    <ArrowSVG className="mil-arrow" fill="#000000" />
                  </button>
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="row justify-content-end">
                <div className="col-md-6 col-lg-7">
                  <div className="mil-footer-menu mil-mb-30">
                    <ul className="p-0">
                      {data.data.map((val, id) => {
                        return (
                          <span key={id}>
                            <a href={val.path}>
                              <li className="mil-up ">
                                <span>{val.label}</span>
                              </li>
                            </a>
                          </span>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 fw-200">
                  <h5
                    className="mil-light-soft"
                    style={{
                      marginBottom: "10px",
                      color: "#fff",
                    }}
                  >
                    Solutions
                  </h5>
                  <ul className="mil-menu-list mil-up mil-mb-30 p-0 fw-200">
                    {data &&
                      data.menuItems &&
                      data.menuItems.map((item, index) => (
                        <li key={index}>
                          <a
                            href={item.href}
                            className="mil-light-soft"
                            style={{ color: item.color }}
                          >
                            {item.text}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-6 mil-mb-20">
              <div className="mil-vert-between text_alignment">
                <div className="mil-mb-30">
                  <ul className="mil-social-icons mil-up">
                    {data.socialIcons.map((social, index) => (
                      <li key={index}>
                        <a
                          href={social.href}
                          target="_blank"
                          className="social-icon"
                        >
                          {social.icon}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <p className="mil-light-soft mil-up fw-200 copywrite">
                  ©Copyright 2024 -
                  <span className="mx-1  text-white">Reality Scale LLC </span>&{" "}
                  <a
                    href="https://www.digixito.com/"
                    className="mx-1 text-white"
                  >
                    Digixito
                  </a>
                  <br />
                  <span className="text-grey">All Rights Reserved.</span>
                </p>
              </div>
            </div>
            <div className="cl-sm-12 col-md-7 col-lg-6">
              <div className="mobilescreen_nav">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box>
                      <Box>
                        <h6 className="mil-muted mil-up mil-mb-10 mb-0">
                          India{" "}
                        </h6>
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                          }}
                        >
                          (Operation Office)
                        </span>
                      </Box>

                      <p
                        className="mil-light-soft mil-up mt-2"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                        }}
                      >
                        2nd Floor, D-320, Sector 63, Noida, UP,
                        <br /> 201307 INDIA
                      </p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box>
                      <h6 className="mil-muted mil-up mil-mb-10 mb-0">USA </h6>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          // margin: "0 5px",
                        }}
                      >
                        (Head Office)
                      </span>
                      <p
                        className="mil-light-soft mil-up mt-2"
                        style={{
                          fontSize: "17px",
                          fontWeight: 700,
                          color: "#fff",
                        }}
                      >
                        30 N Gould St Ste R Sheridan,
                        <br />
                        WY 82801 USA
                      </p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box>
                      <h6 className="mil-muted mil-up mil-mb-10 mb-0">
                        India{" "}
                      </h6>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                        }}
                      >
                        (Operation Office)
                      </span>

                      <p
                        className="mil-light-soft mil-up mt-2"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#fff",
                        }}
                      >
                        D-597, Sector-6, C.D.A, Cuttack, Odisha,
                        <br /> Pin-753014
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
